import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ApiClient, ApiContact, ApiInvoice, ApiRegister, InvoiceType} from "../../../../utils/http/apiClient";
import {createIdMap} from "../../../../utils/objectUtils";
import {Box, Button, Flex, LoadingOverlay, Space, Table, Title} from "@mantine/core";
import {IconColumns3, IconPlus, IconReceipt} from "@tabler/icons-react";
import {InvoiceModal} from "./InvoiceModal";
import {StatusBadge} from "../../../../common/StatusBadge";
import {formatDate} from "../../../../utils/date/dateUtils";
import {formatAmount} from "../../../../utils/formatUtils";
import {ObjectLink} from "../../../../common/ObjectLink";
import {ContactModal} from "../contacts/ContactModal";
import {RegisterModal} from "../register/RegisterModal";

interface Props {
  invoiceType: InvoiceType
}

export const InvoiceList = ({invoiceType}: Props) => {
  const organisationId = useOrganisationId();
  const [invoices, setInvoices] = useState<ApiInvoice[]>([]);
  const [contactsById, setContactsById] = useState<Record<string, ApiContact>>({});
  const [registersById, setRegistersById] = useState<Record<string, ApiRegister>>({});
  const [loading, setLoading] = useState(false);

  const modalRefs = {
    invoice: useRef<any>(),
    contact: useRef<any>(),
    register: useRef<any>(),
  };

  const fetchEntities = useCallback(() => {
    if (organisationId) {
      setLoading(true);
      Promise.all([
        ApiClient.getInvoices(organisationId, invoiceType),
        invoiceType === 'INCOMING_INVOICE' ? ApiClient.getContacts(organisationId) : null,
        invoiceType === 'REGISTER_REPORT' ? ApiClient.getRegisters(organisationId) : null
      ]).then(([invoices, contacts, registers]) => {
        setInvoices(invoices.data);
        setContactsById(createIdMap(contacts?.data ?? []));
        setRegistersById(createIdMap(registers?.data ?? []));
      }).finally(() => setLoading(false));
    }
  }, [organisationId]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const titleMap = {
    'INCOMING_INVOICE': 'Incoming Invoices',
    'REGISTER_REPORT': 'Register reports'
  } as Record<InvoiceType, string>;

  const objectNameMap = {
    'INCOMING_INVOICE': 'Supplier',
    'REGISTER_REPORT': 'Register'
  } as Record<InvoiceType, string>;

  const mapObjectName = {
    'INCOMING_INVOICE': (invoice: ApiInvoice) => contactsById[invoice.objectId ?? '']?.name,
    'REGISTER_REPORT': (invoice: ApiInvoice) => registersById[invoice.objectId ?? '']?.name,
  } as Record<InvoiceType, (invoice: ApiInvoice) => string>;

  const openModal = (invoiceId: string | undefined) => {
    modalRefs.invoice.current?.openModal(invoiceType, invoiceId);
  }

  const openContactModal = (contactId: string | undefined | null) => {
    modalRefs.contact.current?.openModal(contactId);
  }

  const openRegisterModal = (registerId: string | undefined | null) => {
    modalRefs.register.current?.openModal(registerId);
  }

  return <>
    <Flex direction="row" align="center">
      <IconReceipt size={35} stroke={1.5}/>
      <Title size={24}>
        {titleMap[invoiceType]}
      </Title>
      <Space w={20}/>
      <Button size="xs" rightSection={<IconPlus/>} onClick={() => openModal(undefined)}>
        New
      </Button>
    </Flex>

    <Box style={{paddingTop: '15px'}}>
      <LoadingOverlay visible={loading}/>
      <Table style={{width: "1%", minWidth: "900px"}}
             verticalSpacing={1}
             withTableBorder
             withColumnBorders
             highlightOnHover={true}
             className="datatable">
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{width: "1%", textAlign: "right"}}>
              <IconColumns3 style={{marginTop: '5px'}}/>
            </Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Status</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Booking date</Table.Th>
            <Table.Th style={{textAlign: "left"}}>{objectNameMap[invoiceType]}</Table.Th>
            {invoiceType === 'INCOMING_INVOICE'
              ? <Table.Th style={{textAlign: "left"}}>Invoice No.</Table.Th>
              : <></>}
            <Table.Th style={{width: "1%", textAlign: "right"}}>Amount</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Currency</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Issue date</Table.Th>

            {invoiceType === 'INCOMING_INVOICE'
              ? <>
                <Table.Th style={{width: "1%", textAlign: "center"}}>Due date</Table.Th>
                <Table.Th style={{width: "1%", textAlign: "center"}}>Delivery date</Table.Th>
              </>
              : <></>}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {invoices.map((invoice, idx) => (
            <Table.Tr key={idx}>
              <Table.Td style={{textAlign: "center"}} onClick={() => openModal(invoice.id)}>
                {idx + 1}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => openModal(invoice.id)}>
                <StatusBadge status={invoice.bookingStatus}/>
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => openModal(invoice.id)}>
                {formatDate(invoice.bookingDate)}
              </Table.Td>
              <Table.Td style={{textAlign: "left"}} onClick={() => openModal(invoice.id)}>
                {invoiceType === 'INCOMING_INVOICE'
                  ? <ObjectLink name={mapObjectName[invoiceType](invoice)}
                                onClick={() => openContactModal(invoice.objectId)}/>
                  : <></>}
                {invoiceType === 'REGISTER_REPORT'
                  ? <ObjectLink name={mapObjectName[invoiceType](invoice)}
                                onClick={() => openRegisterModal(invoice.objectId)}/>
                  : <></>}
              </Table.Td>
              {invoiceType === 'INCOMING_INVOICE'
                ? <Table.Td onClick={() => openModal(invoice.id)}>
                  {invoice.invoiceNumber}
                </Table.Td>
                : <></>}
              <Table.Td style={{textAlign: "right"}} onClick={() => openModal(invoice.id)}>
                {formatAmount(invoice.grossAmount ?? NaN)}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => openModal(invoice.id)}>
                {invoice.currency}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => openModal(invoice.id)}>
                {formatDate(invoice.issueDate)}
              </Table.Td>
              {invoiceType === 'INCOMING_INVOICE'
                ? <>
                  <Table.Td style={{textAlign: "center"}} onClick={() => openModal(invoice.id)}>
                    {formatDate(invoice.dueDate)}
                  </Table.Td>
                  <Table.Td style={{textAlign: "center"}} onClick={() => openModal(invoice.id)}>
                    {formatDate(invoice.deliveryDate)}
                  </Table.Td>
                </>
                : <></>}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Box>

    <InvoiceModal ref={modalRefs.invoice} onSuccess={fetchEntities}/>
    <ContactModal ref={modalRefs.contact} onSuccess={fetchEntities}/>
    <RegisterModal ref={modalRefs.register} onSuccess={fetchEntities}/>
  </>;
}