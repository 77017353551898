import {Dialog, Divider, NavLink} from "@mantine/core";
import React, {useCallback, useEffect, useImperativeHandle, useState} from "react";
import {Box, BoxGroup} from "./canvas";
import {IconClick, IconDeselect, IconLayersLinked, IconLayersUnion, IconSquaresFilled} from "@tabler/icons-react";

export interface SuccessResponse {
  selectedMenuItem: string
  hoveredBox: Box | null
  hoveredBoxGroup: BoxGroup | null
}

interface Props {
  onSuccess: (response: SuccessResponse) => void
}

export const FileCanvasContextMenu = React.forwardRef(({onSuccess}: Props, ref) => {
  const [open, setOpen] = useState(false);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [hoveredBox, setHoveredBox] = useState<Box | null>();
  const [hoveredBoxGroup, setHoveredBoxGroup] = useState<BoxGroup | null>();


  const openMenu = useCallback((left: number, top: number, hoveredBox: Box, hoveredBoxGroup: BoxGroup) => {
    setLeft(left);
    setTop(top);
    setHoveredBox(hoveredBox);
    setHoveredBoxGroup(hoveredBoxGroup);
    setOpen(true);
  }, [open]);

  useImperativeHandle(ref, () => ({openMenu}));

  useEffect(() => {
    const handleClick = () => setOpen(false);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const handleClick = (selectedMenuItem: string) => {
    onSuccess({selectedMenuItem, hoveredBox, hoveredBoxGroup} as SuccessResponse);
    setOpen(false);
  }

  return <Dialog opened={open}
                 position={{top, left}} transitionProps={{transition: 'pop', duration: 0}}
                 className={"canvas-context-menu"}>
    <NavLink label="Mode: Selection"
             onClick={() => handleClick("MODE_SELECTION")}
             leftSection={<IconClick size="1rem" stroke={1.5}/>}/>
    <NavLink label="Mode: Merge"
             onClick={() => handleClick("MODE_MERGE")}
             leftSection={<IconLayersLinked size="1rem" stroke={1.5}/>}/>
    <NavLink label="Mode: Remove group"
             onClick={() => handleClick("MODE_REMOVE_GROUP")}
             leftSection={<IconSquaresFilled size="1rem" stroke={1.5}/>}/>
    <Divider/>
    <NavLink label="Group into lines" onClick={() => handleClick("GROUP_INTO_LINES")}/>
    <NavLink label="Remove box" onClick={() => handleClick("REMOVE_BOX")}/>
    <NavLink label="Remove group" onClick={() => handleClick("REMOVE_BOX_GROUP")}/>
    <Divider/>
    <NavLink label="Clear selection"
             onClick={() => handleClick("CLEAR_SELECTION")}
             leftSection={<IconDeselect size="1rem" stroke={1.5}/>}/>
  </Dialog>;
});