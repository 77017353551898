import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import React, {useEffect, useState} from "react";
import {ApiBalances, ApiClient, ApiItem, BookingType} from "../../../../utils/http/apiClient";
import {Box, Button, Checkbox, Flex, Grid, LoadingOverlay, Table, Text, Title} from "@mantine/core";
import {IconColumns3, IconFilter, IconScale} from "@tabler/icons-react";
import {capitalize, formatAmount} from "../../../../utils/formatUtils";
import {DateInput} from "../../../../common/DateInput";
import {
  daysAgo,
  formatApiDate,
  formatDate,
  startOfMonth,
  startOfWeek,
  startOfYear,
  today
} from "../../../../utils/date/dateUtils";
import {BookingTypeSelect} from "../../../../common/select/BookingTypeSelect";
import {ActionMenu} from "../../../../common/actionButton/ActionMenu";

export const BalanceList = () => {
  const organisationId = useOrganisationId();
  const [balances, setBalances] = useState<ApiBalances | null>(null);
  const [items, setItems] = useState<ApiItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date>(new Date());
  const [bookingTypes, setBookingTypes] = useState<BookingType[]>(['FULL', 'PARTIAL']);

  const [showQuantity, setShowQuantity] = useState(true);
  const [showAmount, setShowAmount] = useState(true);

  const stockedItems = items.filter(i => i.stocked);

  const someItemId = Object.keys(balances?.items ?? {} as ApiBalances)[0];
  const someItemBalances = balances?.items[someItemId] ?? [];
  const dates = someItemBalances.map(b => b.date);

  const setDateRange = (fromDate: Date | null, toDate: Date) => {
    setFromDate(fromDate);
    setToDate(toDate);
  }

  const handleFilter = () => {
    doFilterAndSet(fromDate, toDate, bookingTypes);
  };

  const doFilterAndSet = (fromDate: Date | null, toDate: Date, bookingTypes: BookingType[]) => {
    if (organisationId) {
      setLoading(true);
      Promise.all([
        ApiClient.getBalances(organisationId, formatApiDate(fromDate), formatApiDate(toDate) ?? '', bookingTypes),
        ApiClient.getItems(organisationId)
      ]).then(([balances, items]) => {
        setBalances(balances.data);
        setItems(items.data);
        setFromDate(fromDate);
        setToDate(toDate);
        setBookingTypes(bookingTypes);
      }).finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    handleFilter();
  }, []);

  return <>
    <LoadingOverlay visible={loading}/>
    <Flex direction="row" align="center">
      <IconScale size={35} stroke={1.5}/>
      <Title size={24}>
        Balances
      </Title>
    </Flex>

    <Grid style={{width: '45rem'}} gutter="sm" align="center">
      <Grid.Col span={1}>
        <Text size="sm">From: </Text>
      </Grid.Col>

      <Grid.Col span={2.5}>
        <DateInput value={fromDate}
                   onChange={value => setDateRange(value, toDate)}/>
      </Grid.Col>

      <Grid.Col span={2.7}>
        <Checkbox label="Show amounts" checked={showAmount} onChange={e => setShowAmount(e.target.checked)}/>
      </Grid.Col>

      <Grid.Col span={5.8}>
        <ActionMenu label={"Quick filters"}
                    size="xs"
                    options={[
                      {label: 'Today', action: () => doFilterAndSet(today(), today(), bookingTypes)},
                      'DIVIDER',
                      {label: 'Week to Date', action: () => doFilterAndSet(startOfWeek(), today(), bookingTypes)},
                      {label: 'Month to Date', action: () => doFilterAndSet(startOfMonth(), today(), bookingTypes)},
                      {label: 'Year to Date', action: () => doFilterAndSet(startOfYear(), today(), bookingTypes)},
                      'DIVIDER',
                      {label: 'Last 7 days', action: () => doFilterAndSet(daysAgo(6), today(), bookingTypes)},
                      {label: 'Last 30 days', action: () => doFilterAndSet(daysAgo(29), today(), bookingTypes)},
                      {label: 'Last 90 days', action: () => doFilterAndSet(daysAgo(89), today(), bookingTypes)},
                    ]}/>
      </Grid.Col>

      <Grid.Col span={1}>
        <Text size="sm">To: </Text>
      </Grid.Col>

      <Grid.Col span={2.5}>
        <DateInput value={toDate}
                   onChange={value => setDateRange(fromDate, value ?? new Date())}/>
      </Grid.Col>

      <Grid.Col span={2.7}>
        <Checkbox label="Show quantities" checked={showQuantity} onChange={e => setShowQuantity(e.target.checked)}/>
      </Grid.Col>

      <Grid.Col span={5.8}>
        <Button size="xs" leftSection={<IconFilter size="1rem" stroke={1.5}/>} onClick={handleFilter}>Filter</Button>
      </Grid.Col>

      <Grid.Col span={2}>
        <Text size="sm">Booking Type: </Text>
      </Grid.Col>

      <Grid.Col span={4.2}>
        <BookingTypeSelect values={bookingTypes} onChange={bookingTypes => setBookingTypes(bookingTypes)}/>
      </Grid.Col>

    </Grid>

    <Box style={{paddingTop: '15px'}}>
      <LoadingOverlay visible={loading}/>
      <Table verticalSpacing={1}
             withTableBorder
             withColumnBorders
             highlightOnHover={true}
             className={'datatable'}>
        <Table.Thead>

          <Table.Tr>
            <Table.Th style={{width: "1%", textAlign: "right"}} rowSpan={3}>
              <IconColumns3 style={{marginTop: '5px'}}/>
            </Table.Th>
            <Table.Th style={{textAlign: "center"}} rowSpan={3}>Item</Table.Th>
            <Table.Th style={{textAlign: "center"}}
                      colSpan={showQuantity && showAmount ? 2 * dates.length : dates.length}>
              {capitalize(balances?.balancePeriod ?? '')}
            </Table.Th>
          </Table.Tr>

          <Table.Tr>
            {dates.map(date =>
              <Table.Th key={date} style={{textAlign: "center"}} colSpan={showAmount && showQuantity ? 2 : 1}>
                {formatDate(date)}
              </Table.Th>
            )}
          </Table.Tr>
          <Table.Tr>
            {dates.map((_, idx) =>
              <React.Fragment key={idx}>
                {showQuantity &&
                    <Table.Th key={idx + 'Q'} style={{width: "1%", textAlign: "right"}}>Quantity</Table.Th>}
                {showAmount &&
                    <Table.Th key={idx + 'A'} style={{width: "1%", textAlign: "right"}}>Amount</Table.Th>}
              </React.Fragment>
            )}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {stockedItems.map((item, idx) => (
            <Table.Tr key={idx}>
              <Table.Td style={{textAlign: "center"}}>
                {idx + 1}
              </Table.Td>
              <Table.Td>
                {item.name}
              </Table.Td>

              {(balances?.items[item.id] ?? []).map((balance, idx) => (
                <React.Fragment key={idx + 'QA'}>
                  {showQuantity &&
                      <Table.Td key={balance.itemId + balance.date + 'Q'} style={{textAlign: "right"}}
                                className={balance?.quantity < 0 ? 'negative' : ''}>
                        {formatAmount(balance?.quantity ?? 0)}
                      </Table.Td>}
                  {showAmount &&
                      <Table.Td key={balance.itemId + balance.date + 'A'} style={{textAlign: "right"}}
                                className={balance?.amount < 0 ? 'negative' : ''}>
                        {formatAmount(balance?.amount ?? 0)}
                      </Table.Td>}
                </React.Fragment>
              ))}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Box>
  </>


}