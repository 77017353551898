import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
  ApiClient,
  ApiContact,
  ApiExtraction,
  ApiExtractionLineItem,
  ApiExtractionTaxItem,
  ApiFile,
  ImageAction
} from "../../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../../hooks/useOrganisationId";
import {useDisclosure} from "@mantine/hooks";
import {
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Grid,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Space,
  Table,
  Text
} from "@mantine/core";
import {IconArrowLeft, IconCheck, IconCircleX} from "@tabler/icons-react";
import {createIdMap, addEmptyDefaultElemsIfEmpty, nonEmptyObjects, roundAmount} from "../../../../../utils/objectUtils";
import {Option} from "../../../../../common/datasheet/DataSheetSelect";
import {
  cleanNumber,
  deriveUnitAndQuantity,
  isNumericCheck,
  isUnitCheck,
  isValidTaxLineRowCheck,
  isValidTotalsRowCheck,
  mapInvoiceItems,
  nonEmptyLineItemRequest
} from "./extractionsUtils";
import {DataSheet} from "../../../../../common/datasheet/DataSheet";
import {PageControl} from "../PageControl";
import {FileCanvas} from "../canvas/FileCanvas";
import {Box, mapToBox} from "../canvas/canvas";
import {formatAmount} from "../../../../../utils/formatUtils";
import {ContactSelect} from "../../../../../common/select/ContactSelect";
import {TextInput} from "../../../../../common/TextInput";
import {DateInput} from "../../../../../common/DateInput";
import dayjs from "dayjs";
import {notifyError, notifySavedChanges} from "../../../../../utils/notificationUtils";
import {formatApiDate} from "../../../../../utils/date/dateUtils";
import {ItemModal} from "../../items/ItemModal";
import {InvoiceModal} from "../../invoices/InvoiceModal";
import {ModalTitle} from "../../../../../common/ModalTitle";

interface Props {
  onSuccess?: () => void
  onCancel?: () => void
}

export const ExtractionModal = React.forwardRef(({onSuccess, onCancel}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [fileId, setFileId] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<ApiFile | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const [itemOptions, setItemOptions] = useState<Option[]>([]);
  const [contacts, setContacts] = useState<ApiContact[]>([]);

  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [boxes, setBoxes] = useState<Box[]>([]);
  const [page, setPage] = useState(0);

  const extraction = file?.extraction;

  const modalRefs = {
    item: useRef<any>(),
    incomingInvoice: useRef<any>(),
  };

  const fetchEntities = useCallback(() => {
    if (organisationId && fileId) {
      setLoading(true);
      Promise.all([
        ApiClient.getFile(organisationId, fileId).then(resp => resp.data),
        ApiClient.getItems(organisationId).then(resp => resp.data),
        ApiClient.getUnits(organisationId).then(resp => resp.data),
        ApiClient.getContacts(organisationId).then(resp => resp.data),
      ]).then(([file, items, units, contacts]) => {
        const newFile = {
          ...file,
          extraction: {
            ...file.extraction,
            documentType: file?.extraction?.extractionType ?? 'INCOMING_INVOICE',
            lineItems: addEmptyDefaultElemsIfEmpty(file?.extraction?.lineItems),
            taxItems: addEmptyDefaultElemsIfEmpty(file?.extraction?.taxItems),
          } as ApiExtraction
        } as ApiFile;
        setFile(newFile);
        setBoxes(file.ocrResponse.pages[page].boxes.map((idx, box) => mapToBox(box, idx)) ?? []);
        setContacts(contacts);
        const unitsById = createIdMap(units);
        const itemsOptions = items.map(i => ({
          value: i.id,
          label: `${i.name} [${unitsById[i.unitId ?? '']?.shortName ?? ''}]`
        } as Option));
        setItemOptions(itemsOptions);
      }).catch(notifyError)
        .finally(() => setLoading(false));
    }
  }, [organisationId, fileId]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const fetchContacts = useCallback(() => {
    if (organisationId && fileId) {
      setLoading(true);
      ApiClient.getContacts(organisationId).then(resp => resp.data)
        .then((contacts) => setContacts(contacts))
        .catch(notifyError)
        .finally(() => setLoading(false));
    }
  }, [organisationId, fileId]);

  const fetchItemsAnUnits = useCallback(() => {
    if (organisationId && fileId) {
      setLoading(true);
      Promise.all([
        ApiClient.getItems(organisationId).then(resp => resp.data),
        ApiClient.getUnits(organisationId).then(resp => resp.data),
      ]).then(([items, units]) => {
        const unitsById = createIdMap(units);
        const itemsOptions = items.map(i => ({
          value: i.id,
          label: `${i.name} [${unitsById[i.unitId ?? '']?.shortName ?? ''}]`
        } as Option));
        setItemOptions(itemsOptions);
      }).catch(notifyError)
        .finally(() => setLoading(false));
    }
  }, [organisationId, fileId]);

  const fetchPageImage = () => {
    if (!organisationId || !file) {
      return;
    }
    setLoading(true);
    ApiClient.getFilePageImage(organisationId, file.id, page)
      .then((fileResp) => {
        const img = new Image();
        img.onload = () => setImage(img);
        img.src = 'data:image/png;base64,' + fileResp.data;
      })
      .then(() => {
        setBoxes(file.ocrResponse.pages[page].boxes.map((idx, box) => mapToBox(box, idx)) ?? []);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchPageImage();
  }, [file?.id, page]);

  const openModal = useCallback((fileId: string) => {
    setFileId(fileId);
    open();
  }, [open, fileId]);

  const closeModal = useCallback((success: boolean) => {
    setFileId(undefined);
    close();
    if (success) {
      onSuccess?.();
    }
  }, [close, fileId]);

  useImperativeHandle(ref, () => ({openModal}));

  const handleSave = () => {
    const request = {
      extractionType: extraction?.extractionType ?? 'INCOMING_INVOICE',
      supplierId: extraction?.supplierId,
      invoiceNumber: extraction?.invoiceNumber,
      issueDate: extraction?.issueDate,
      dueDate: extraction?.dueDate,
      deliveryDate: extraction?.deliveryDate,
      netAmount: extraction?.netAmount,
      taxAmount: extraction?.taxAmount,
      grossAmount: extraction?.grossAmount,
      lineItems: (extraction?.lineItems ?? []).filter(nonEmptyLineItemRequest),
      taxItems: nonEmptyObjects(extraction?.taxItems ?? []),
      discountInPercentage: discountInPercentage,
      discountIncludedInAmounts: discountIncludedInAmounts
    } as ApiExtraction;

    if (organisationId && fileId) {
      setLoading(true);
      ApiClient.updateFileExtraction(organisationId, fileId, request)
        .then(notifySavedChanges)
        .then(() => closeModal(true))
        .catch(notifyError)
        .finally(() => setLoading(false));
    }
  };

  const setBodyValue = (key: string, value: any) => {
    setBodyValues([{key, value}]);
  }

  const setBodyValues = (fields: { key: string, value: any }[]) => {
    let updateExtraction = {...extraction} as ApiExtraction;
    for (const field of fields) {
      updateExtraction = applyBodyField(updateExtraction, field.key, field.value);
    }
    setFile({...file, extraction: updateExtraction} as ApiFile)
  }

  const applyBodyField = (fileExtraction: ApiExtraction, key: string, value: any) => {
    const lineItems = mapInvoiceItems(
      key === 'lineItems' ? value : fileExtraction?.lineItems ?? [],
      key === 'discountInPercentage' ? value : discountInPercentage,
      key === 'discountIncludedInAmounts' ? value : discountIncludedInAmounts);

    return {
      ...fileExtraction,
      [key]: value,
      lineItems: lineItems
    } as ApiExtraction;
  }

  const discountInPercentage = extraction?.discountInPercentage ?? true;
  const discountIncludedInAmounts = extraction?.discountIncludedInAmounts ?? true;
  const setDiscountInPercentage = (checked: boolean) => setBodyValue('discountInPercentage', checked);
  const setDiscountIncludedInAmounts = (checked: boolean) => setBodyValue('discountIncludedInAmounts', checked);

  const [computedTaxItems, setComputedTaxItems] = useState<ApiExtractionTaxItem[]>([]);
  const [computedTotals, setComputedTotals] = useState([{
    name: 'Total',
    netAmount: '',
    taxAmount: '',
    grossAmount: ''
  }]);

  useEffect(() => {
    const lineItems = (extraction?.lineItems ?? []);
    const taxRates = new Set(
      lineItems.map(li => cleanNumber(li.tax))
        .map(li => li ? li : 'NaN')
        .map(li => Number(li)));
    const netAmounts: Record<string, number> = {};
    const taxAmounts: Record<string, number> = {};
    const grossAmounts: Record<string, number> = {};

    lineItems.forEach(li => {
      const taxRate = Number(cleanNumber(li.tax));
      netAmounts[taxRate] = taxRate in netAmounts ? netAmounts[taxRate] : 0;
      grossAmounts[taxRate] = taxRate in grossAmounts ? grossAmounts[taxRate] : 0;
      taxAmounts[taxRate] = taxRate in taxAmounts ? taxAmounts[taxRate] : 0;
      netAmounts[taxRate] += Number(li.mappedNetAmount);
      grossAmounts[taxRate] += Number(li.mappedGrossAmount);
      taxAmounts[taxRate] += (Number(li.mappedGrossAmount) - Number(li.mappedNetAmount));
    });
    const taxItems = Array.from(taxRates).map(tr => ({
      rate: formatAmount(tr),
      netAmount: formatAmount(roundAmount(netAmounts[tr])),
      taxAmount: formatAmount(roundAmount(taxAmounts[tr])),
      grossAmount: formatAmount(roundAmount(grossAmounts[tr])),
    } as ApiExtractionTaxItem));

    setComputedTaxItems(taxItems);

    // TODO: Change these so they store clean numbers and apply the format only in the DataSheet column itself
    setComputedTotals([{
      name: 'Total',
      netAmount: formatAmount(taxItems.map(ti => Number(cleanNumber(ti.netAmount))).reduce((acc, curr) => acc + curr, 0)),
      taxAmount: formatAmount(taxItems.map(ti => Number(cleanNumber(ti.taxAmount))).reduce((acc, curr) => acc + curr, 0)),
      grossAmount: formatAmount(taxItems.map(ti => Number(cleanNumber(ti.grossAmount))).reduce((acc, curr) => acc + curr, 0)),
    }]);
  }, [extraction]);

  const [totals, setTotals] = useState([{name: 'Total', netAmount: '', taxAmount: '', grossAmount: ''}]);
  useEffect(() => {
    setTotals([{
      name: 'Total',
      netAmount: extraction?.netAmount ?? '',
      taxAmount: extraction?.taxAmount ?? '',
      grossAmount: extraction?.grossAmount ?? '',
    }]);
  }, [extraction]);

  const deriveUnitsAndQuantities = () => {
    const newLineItems = extraction?.lineItems.map(lineItem => {
      const unitAndQuantity = deriveUnitAndQuantity(lineItem.text);
      return {
        ...lineItem,
        unit1: unitAndQuantity.unit,
        quantity1: unitAndQuantity.quantity
      } as ApiExtractionLineItem
    });
    setBodyValue("lineItems", newLineItems);
  }

  const performImageAction = useCallback((action: ImageAction) => {
    if (organisationId && fileId) {
      setLoading(true);
      ApiClient.editFilePageImage(organisationId, fileId, page, action)
        .then(() => fetchEntities())
        .finally(() => setLoading(false));
    }
  }, [organisationId, fileId, page]);


  const copyCalculatedTaxItems = () => {
    const mappedItems = computedTaxItems.map(taxItem => ({
      rate: taxItem.rate,
      netAmount: taxItem.netAmount,
      taxAmount: taxItem.taxAmount,
      grossAmount: taxItem.grossAmount,
    } as ApiExtractionTaxItem));
    setBodyValue('taxItems', mappedItems);
  }

  const copyCalculatedTotals = () => {
    setBodyValues([
      {key: 'netAmount', value: computedTotals[0].netAmount?.trim()},
      {key: 'taxAmount', value: computedTotals[0].taxAmount?.trim()},
      {key: 'grossAmount', value: computedTotals[0].grossAmount?.trim()},
    ]);
  }

  return <Modal opened={opened}
                id="file_extraction_modal"
                onClose={() => closeModal(false)}
                title={<ModalTitle name={"Extraction (file)"} id={fileId}/>}
                closeOnClickOutside={false}
                transitionProps={{duration: 100}}
                overlayProps={{opacity: 0.5}}
                fullScreen={true}
  >
    <LoadingOverlay visible={loading}/>
    <Flex align="center" direction="column" gap="md">
      <Flex direction="row" gap="md">
        <Flex direction="column"
              style={{
                position: 'sticky',
                top: 60,
                height: '100%',
                maxHeight: `${window.innerHeight - 150}}px`
              }}>
          <PageControl pageCount={file?.ocrResponse.pages.length ?? 0}
                       currentPage={page}
                       onPageChange={(page) => setPage(page)}
                       onImageAction={performImageAction}/>
          <FileCanvas image={image} boxes={boxes} width={600} height={window.innerHeight - 150}/>
        </Flex>

        <Flex direction="column">

          <Space h={20}/>
          <Divider/>
          <Space h={20}/>

          <Flex direction={"column"} gap="md" style={{width: '37rem', height: '2rem'}} align={"center"}>
            <Text c="dimmed" size="sm">Supplier & dates</Text>
          </Flex>

          <Table style={{width: 'fit-content'}} withRowBorders={false}>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td>Supplier: </Table.Td>
                <Table.Td>
                  <ContactSelect contactId={extraction?.supplierId ?? undefined}
                                 contacts={contacts}
                                 onChange={contactId => setBodyValue('supplierId', contactId)}
                                 onNewCreated={fetchContacts}
                                 tabIndex={1}/>
                </Table.Td>
                <Table.Td> Issue date: </Table.Td>
                <Table.Td style={{width: '150px'}}>
                  <DateInput
                    value={extraction?.issueDate ? dayjs(extraction?.issueDate).toDate() : null}
                    onChange={date => setBodyValue('issueDate', formatApiDate(date))}
                    tabIndex={3}/>
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Invoice number: </Table.Td>
                <Table.Td>
                  <TextInput label="Invoice Number"
                             value={extraction?.invoiceNumber ?? null}
                             onChange={vale => setBodyValue('invoiceNumber', vale)}
                             tabIndex={2}/>
                </Table.Td>
                <Table.Td>Due date: </Table.Td>
                <Table.Td>
                  <DateInput
                    value={extraction?.dueDate ? dayjs(extraction?.dueDate).toDate() : null}
                    onChange={date => setBodyValue('dueDate', formatApiDate(date))}
                    tabIndex={4}/>
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td colSpan={2} style={{textAlign: 'center'}}>
                  {/*<SimilarInvoices organisationId={organisationId} apiExtraction={extraction}/>*/}
                </Table.Td>
                <Table.Td>Delivery date: </Table.Td>
                <Table.Td>
                  <DateInput
                    value={extraction?.deliveryDate ? dayjs(extraction?.deliveryDate).toDate() : null}
                    onChange={date => setBodyValue('deliveryDate', formatApiDate(date))}
                    tabIndex={5}/>
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>

          <Space h={20}/>
          <Divider/>
          <Space h={20}/>

          <ScrollArea w={window.innerWidth - 800} type="auto">
            <DataSheet
              caption={"Line items"}
              showLineNumber={true}
              columns={[
                {
                  name: 'text',
                  displayName: 'Text',
                  type: 'TEXT',
                  align: 'left'
                },
                {
                  name: 'quantity1',
                  displayName: 'Qty1',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isNumericCheck],
                },
                {
                  name: 'unit1',
                  displayName: 'Unit1',
                  type: 'TEXT',
                  align: 'center',
                  errorCheckers: [isUnitCheck],
                },
                {
                  name: 'quantity2',
                  displayName: 'Qty2',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isNumericCheck],
                },
                {
                  name: 'unit2',
                  displayName: 'Unit2',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isUnitCheck],
                },
                {
                  name: 'quantity3',
                  displayName: 'Qty3',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isNumericCheck],
                },
                {
                  name: 'discount',
                  displayName: 'Discount',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isNumericCheck],
                },
                {
                  name: 'tax',
                  displayName: 'Tax',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isNumericCheck],
                },
                {
                  name: 'netAmount',
                  displayName: 'Net Amount',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isNumericCheck],
                },
                {
                  name: 'grossAmount',
                  displayName: 'Gross Amount',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isNumericCheck],
                },
                {
                  name: 'mappedItemId',
                  displayName: 'Item [M]',
                  type: 'SELECT',
                  align: 'center',
                  selectOptions: itemOptions,
                  modalRef: modalRefs.item
                },
                {
                  name: 'mappedQuantity',
                  displayName: 'Qty [M]',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isNumericCheck],
                  readOnly: true
                },
                {
                  name: 'mappedUnit',
                  displayName: 'Unit [M]',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isUnitCheck],
                  readOnly: true
                },
                {
                  name: 'mappedUnitPrice',
                  displayName: 'Unit Price [M]',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isNumericCheck],
                  readOnly: true
                },
                {
                  name: 'mappedNetAmount',
                  displayName: 'Net Amount [M]',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isNumericCheck],
                  readOnly: true
                },
                {
                  name: 'mappedGrossAmount',
                  displayName: 'Gross Amount [M]',
                  type: 'TEXT',
                  align: 'right',
                  errorCheckers: [isNumericCheck],
                  readOnly: true
                },
              ]}
              rows={extraction?.lineItems ?? []}
              updateRows={(rows) => {
                const newLineItems = rows.map(row => {
                  const lineItem = {} as ApiExtractionLineItem;
                  Object.keys(row).forEach(col => lineItem[col] = row[col]?.trim())
                  return lineItem;
                });
                setBodyValue('lineItems', newLineItems);
              }}
              rowErrorCheckers={[]}
            />
            <Space h={5}/>
          </ScrollArea>

          <Space h={20}/>

          <Flex direction={"row"} gap={5} justify={"right"} align={"center"}>
            <Button onClick={deriveUnitsAndQuantities} size="xs">Derive units and quantities</Button>
            <Checkbox checked={discountInPercentage}
                      onChange={e => setDiscountInPercentage(e.target.checked)}
                      label={"Discount in percentage"}/>
            <Checkbox checked={discountIncludedInAmounts}
                      onChange={e => setDiscountIncludedInAmounts(e.target.checked)}
                      label={"Discount already included in amounts"}/>

          </Flex>

          <Space h={20}/>
          <Divider/>
          <Space h={20}/>


          <div style={{width: '900px'}}>
            <Grid align="center" justify="space-between">
              <Grid.Col span={5}>
                <Center>
                  <DataSheet
                    caption={"VAT items"}
                    columns={[
                      {
                        name: 'rate',
                        displayName: 'Tax Rate',
                        type: 'TEXT',
                        align: 'right',
                        errorCheckers: [isNumericCheck],
                      },
                      {
                        name: 'netAmount',
                        displayName: 'Net Amount',
                        type: 'TEXT',
                        align: 'right',
                        errorCheckers: [isNumericCheck],
                      },
                      {
                        name: 'taxAmount',
                        displayName: 'Tax Amount',
                        type: 'TEXT',
                        align: 'right',
                        errorCheckers: [isNumericCheck],
                      },
                      {
                        name: 'grossAmount',
                        displayName: 'Gross Amount',
                        type: 'TEXT',
                        align: 'right',
                        errorCheckers: [isNumericCheck],
                      },
                    ]}
                    rows={extraction?.taxItems ?? []}
                    updateRows={(rows) => {
                      const newVatItems = rows.map(row => {
                        const taxItem = {} as ApiExtractionTaxItem;
                        Object.keys(row).forEach(col => taxItem[col] = row[col]?.trim())
                        return taxItem;
                      });
                      setBodyValue('taxItems', newVatItems);
                    }}
                    rowErrorCheckers={[isValidTaxLineRowCheck]}
                  />
                </Center>
              </Grid.Col>

              <Grid.Col span={2}>
                <Center>
                  <Button variant={"light"} size={"xs"} leftSection={<IconArrowLeft/>}
                          onClick={copyCalculatedTaxItems}>
                    Copy
                  </Button>
                </Center>
              </Grid.Col>

              <Grid.Col span={5}>
                <Center>
                  <DataSheet
                    caption={"Calculated VAT summary"}
                    columns={[
                      {name: 'rate', displayName: 'Tax Rate', type: 'TEXT', align: 'right', readOnly: true},
                      {name: 'netAmount', displayName: 'Net Amount', type: 'TEXT', align: 'right', readOnly: true},
                      {name: 'taxAmount', displayName: 'Tax Amount', type: 'TEXT', align: 'right', readOnly: true},
                      {
                        name: 'grossAmount',
                        displayName: 'Gross Amount',
                        type: 'TEXT',
                        align: 'right',
                        readOnly: true
                      },
                    ]}
                    rows={computedTaxItems}
                    updateRows={() => null}
                    rowErrorCheckers={[]}
                  />
                </Center>
              </Grid.Col>

              <Grid.Col span={5}>
                <Center>
                  <DataSheet
                    caption={"Totals"}
                    columns={[
                      {
                        name: 'name',
                        displayName: 'Name',
                        type: 'TEXT',
                        align: 'right',
                        readOnly: true
                      },
                      {
                        name: 'netAmount',
                        displayName: 'Net Amount',
                        type: 'TEXT',
                        align: 'right',
                        errorCheckers: [isNumericCheck],
                      },
                      {
                        name: 'taxAmount',
                        displayName: 'Tax Amount',
                        type: 'TEXT',
                        align: 'right',
                        errorCheckers: [isNumericCheck],
                      },
                      {
                        name: 'grossAmount',
                        displayName: 'Gross Amount',
                        type: 'TEXT',
                        align: 'right',
                        errorCheckers: [isNumericCheck],
                      },
                    ]}
                    rows={totals}
                    updateRows={(rows) => {
                      setBodyValues([
                        {key: 'netAmount', value: rows[0].netAmount?.trim()},
                        {key: 'taxAmount', value: rows[0].taxAmount?.trim()},
                        {key: 'grossAmount', value: rows[0].grossAmount?.trim()},
                      ]);
                    }}
                    rowErrorCheckers={[isValidTotalsRowCheck]}
                  />
                </Center>
              </Grid.Col>

              <Grid.Col span={2}>
                <Center>
                  <Button variant={"light"} size={"xs"} leftSection={<IconArrowLeft/>} onClick={copyCalculatedTotals}>
                    Copy
                  </Button>
                </Center>
              </Grid.Col>

              <Grid.Col span={5}>
                <Center>
                  <DataSheet
                    caption={"Calculated Totals"}
                    columns={[
                      {name: 'name', displayName: 'Name', type: 'TEXT', align: 'right', readOnly: true},
                      {name: 'netAmount', displayName: 'Net Amount', type: 'TEXT', align: 'right', readOnly: true},
                      {name: 'taxAmount', displayName: 'Tax Amount', type: 'TEXT', align: 'right', readOnly: true},
                      {
                        name: 'grossAmount',
                        displayName: 'Gross Amount',
                        type: 'TEXT',
                        align: 'right',
                        readOnly: true
                      },
                    ]}
                    rows={computedTotals}
                    updateRows={() => null}
                    rowErrorCheckers={[]}
                  />
                </Center>
              </Grid.Col>
            </Grid>

          </div>

          <Space h={20}/>
          <Divider/>
          <Space h={20}/>

          <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
            <Button type="submit" rightSection={<IconCheck/>} loading={loading} onClick={handleSave}>
              Save
            </Button>
            <Button variant="outline" leftSection={<IconCircleX/>} onClick={() => closeModal(false)}
                    disabled={loading}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
    <ItemModal ref={modalRefs.item} onSuccess={fetchItemsAnUnits}/>
    <InvoiceModal ref={modalRefs.incomingInvoice} onSuccess={() => null}/>
  </Modal>
});