import {AppShell} from "@mantine/core";
import {Outlet} from "react-router-dom";
import React from "react";
import {PrivateShellHeader} from "./PrivateShellHeader";
import {PrivateShellNavbar} from "./PrivateShellNavbar";

export const PrivateShell = () => {
  return <AppShell header={{height: 60}} padding="md" navbar={{width: 220, breakpoint: 'sm'}}>
    <AppShell.Header>
      <PrivateShellHeader/>
    </AppShell.Header>

    <AppShell.Navbar>
      <PrivateShellNavbar/>
    </AppShell.Navbar>

    <AppShell.Main>
      <Outlet/>
    </AppShell.Main>
  </AppShell>;
}