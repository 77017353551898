import {Button, Dialog, Divider, Flex, MantineSize, NavLink} from "@mantine/core";
import {IconChevronDown} from "@tabler/icons-react";
import React, {useEffect, useRef, useState} from "react";

export interface ActionOption {
  label: React.ReactNode
  action: () => void
  disabled?: boolean
  leftSection?: React.ReactNode,
}

export type ActionMenuDivider = 'DIVIDER'
export type ActionMenuItem = ActionOption | ActionMenuDivider

interface Props {
  label: string
  size?: MantineSize,
  options: ActionMenuItem[]
}

export const ActionMenu = ({label, options, size}: Props) => {
  const [open, setOpen] = useState(false);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const buttonRef = useRef<any>();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);

    const buttonRect = buttonRef.current?.getBoundingClientRect();
    setLeft(buttonRect.left);
    setTop(buttonRect.top + buttonRect.height + 2);
  }

  useEffect(() => {
    const handleClick = () => setOpen(false);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const dialogWidth = 400;
  const dialogHeight = 300;
  const position = {
    left: left + dialogWidth <= window.innerWidth ? left : undefined,
    right: left + dialogWidth <= window.innerWidth ? undefined : window.innerWidth - left,
    top: top + dialogHeight <= window.innerHeight ? top : undefined,
    bottom: top + dialogHeight <= window.innerHeight ? undefined : window.innerHeight - top,
  };

  return <>
    <Flex direction="row">
      <Button ref={buttonRef}
              onClick={handleMenuClick}
              rightSection={<IconChevronDown size="1.5rem" stroke={1.5}/>}
              size={size}
              variant={"light"}>
        {label}
      </Button>
    </Flex>

    <Dialog opened={open}
            position={position}
            transitionProps={{transition: 'pop', duration: 0}}
            className={"action-menu-context-menu"}
            style={{width: 'fit-content'}}
    >
      {options.map((option, idx) =>
        option === 'DIVIDER'
          ? <Divider/>
          : <NavLink key={idx}
                     label={option.label}
                     onClick={() => option.action()}
                     leftSection={option.leftSection}
                     disabled={option.disabled}/>
      )}
    </Dialog>

  </>

}