import {Button, Dialog, Divider, Flex, NavLink} from "@mantine/core";
import {IconChevronDown} from "@tabler/icons-react";
import React, {useEffect, useRef, useState} from "react";

export interface ActionOption {
  label: React.ReactNode
  action: () => void
  default?: boolean
  disabled?: boolean
  leftSection?: React.ReactNode
}

export type ActionMenuDivider = 'DIVIDER'
export type ActionMenuItem = ActionOption | ActionMenuDivider

interface Props {
  defaultAction: ActionOption
  options: ActionMenuItem[]
}

export const ActionButton = ({defaultAction, options}: Props) => {
  const [open, setOpen] = useState(false);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const buttonRef = useRef<any>();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);

    const buttonRect = buttonRef.current?.getBoundingClientRect();
    setLeft(buttonRect.left);
    setTop(buttonRect.top + buttonRect.height + 2);
  }

  useEffect(() => {
    const handleClick = () => setOpen(false);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);


  const dialogWidth = 340;
  const dialogHeight = 300;
  // buttonRef?.current.top;
  const position = {
    left: left + dialogWidth <= window.innerWidth ? left : undefined,
    right: left + dialogWidth <= window.innerWidth ? undefined : window.innerWidth - left,
    top: top + dialogHeight <= window.innerHeight ? top : undefined,
    bottom: top + dialogHeight <= window.innerHeight ? undefined : window.innerHeight - top + 30,
  };

  return <>
    <Flex direction="row">
      <Button ref={buttonRef}
              onClick={() => defaultAction.action()}
              leftSection={defaultAction.leftSection}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}>
        {defaultAction.label}
      </Button>
      <Button onClick={handleMenuClick}
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: '1px solid white',
                margin: 0,
                padding: 0
              }}>
        <IconChevronDown size="1.5rem" stroke={1.5}/>
      </Button>
    </Flex>

    <Dialog opened={open}
            position={position}
            transitionProps={{transition: 'pop', duration: 0}}
            className={"action-menu-context-menu"}
            style={{width: 'fit-content'}}
            withinPortal={true}
    >
      {options.map((option, idx) =>
        option === 'DIVIDER'
          ? <Divider key={idx}/>
          : <NavLink key={idx}
                     label={option.label}
                     onClick={() => option.action()}
                     leftSection={option.leftSection}
                     disabled={option.disabled}/>
      )}
    </Dialog>

  </>

}