import {Anchor, Space, Stack, Table} from "@mantine/core";
import {FileUploadButton} from "./FileUploadButton";
import {IconColumns3} from "@tabler/icons-react";
import {StatusBadge} from "../../../../common/StatusBadge";
import React, {RefObject, useRef} from "react";
import {ApiContact, ApiFile} from "../../../../utils/http/apiClient";
import {ContactModal} from "../contacts/ContactModal";
import {formatDate} from "../../../../utils/date/dateUtils";
import {formatAmount} from "../../../../utils/formatUtils";
import {ObjectLink} from "../../../../common/ObjectLink";

interface Props {
  files: ApiFile[]
  handleFileUpload: (files: FileList | null) => void
  fileUploadModalRef: RefObject<any>
  contactsById: Record<string, ApiContact>
}

export const FilesTab = ({files, handleFileUpload, fileUploadModalRef, contactsById}: Props) => {
  const modalRefs = {
    extraction: useRef<any>(),
    incomingInvoice: useRef<any>(),
    contact: useRef<any>(),
  };

  return <>
    <Space h={10}/>
    <Stack dir="row" align="flex-start" justify="space-around">
      <FileUploadButton onFileChange={handleFileUpload}/>

      <Table style={{width: "1%", minWidth: "700px", whiteSpace: "nowrap"}}
             verticalSpacing={1}
             withTableBorder
             withColumnBorders
             highlightOnHover={true}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{width: "1%", textAlign: "right"}}>
              <IconColumns3 style={{marginTop: '5px'}}/>
            </Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Status</Table.Th>
            <Table.Th style={{textAlign: "left"}}>Name</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Document Type</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "left"}}>Supplier</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Invoice Number</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Issue Date</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Amount</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {files.map((file, idx) => (
            <Table.Tr key={idx}>
              <Table.Td style={{textAlign: "center"}}
                        onClick={() => fileUploadModalRef.current?.openModal(file.id)}>
                {idx + 1}
              </Table.Td>
              <Table.Td onClick={() => fileUploadModalRef.current?.openModal(file.id)}>
                <StatusBadge status={file.status}/>
              </Table.Td>
              <Table.Td onClick={() => fileUploadModalRef.current?.openModal(file.id)}>
                {file.name}
              </Table.Td>
              <Table.Td style={{textAlign: "right"}}
                        onClick={() => fileUploadModalRef.current?.openModal(file.id)}>
                {file.extractedType}
              </Table.Td>
              <Table.Td style={{textAlign: "left"}}
                        onClick={() => fileUploadModalRef.current?.openModal(file.id)}>
                <ObjectLink name={contactsById[file?.extractedSupplierId ?? '']?.name}
                            onClick={() => modalRefs.contact.current.openModal(contactsById[file?.extractedSupplierId ?? ''])}/>
              </Table.Td>
              <Table.Td style={{textAlign: "center"}}
                        onClick={() => fileUploadModalRef.current?.openModal(file.id)}>
                {file.extractedInvoiceNumber}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}}
                        onClick={() => fileUploadModalRef.current?.openModal(file.id)}>
                {formatDate(file.extractedIssueDate)}
              </Table.Td>
              <Table.Td style={{textAlign: "right"}}
                        onClick={() => fileUploadModalRef.current?.openModal(file.id)}>
                {formatAmount(file.extractedAmount ?? NaN)}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Stack>

    <ContactModal ref={modalRefs.contact}/>
  </>;
}