import {Flex, NavLink, Title} from "@mantine/core";
import {ApiClient, ApiOrganisation} from "../../utils/http/apiClient";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export const UserHome = () => {
  const navigate = useNavigate();
  const [organisations, setOrganisations] = useState<ApiOrganisation[]>([]);

  useEffect(() => {
    ApiClient.getOrganisations()
      .then(resp => setOrganisations(resp.data));
  }, []);

  return <>
    <Flex direction="column">
      <Title order={3} style={{padding: '10px'}}>User Home</Title>

      {organisations.map((org, idx) => (
        <NavLink key={idx} label={org.name} href={`/app/organisation/${org.id}`} />
      ))}
    </Flex>
  </>;
}