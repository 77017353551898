import {Flex, Title} from "@mantine/core";
import React from "react";
import {useNavigate} from "react-router-dom";

export const PublicShellHeader = () => {
  const navigate = useNavigate();

  return <>
    <Flex justify="space-between" align="center" bg="blue.7" style={{height: "100%"}}>
      <Title order={1} style={{color: "white", marginLeft: '20px'}}>
        munita
      </Title>
    </Flex>
  </>
};