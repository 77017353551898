import axios from "axios";
import {getAccessToken} from "../token/tokenCache";

export const REQUEST_TIMEOUT = 60_000;

export const httpClient = axios.create({
  timeout: REQUEST_TIMEOUT
});

httpClient.interceptors.request.use(
  (request) => {
    const accessToken = getAccessToken();
    if (accessToken && request.headers) {
      request.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return request
  },
  (error) => {
    return Promise.reject(error);
  }
)
