import {Divider, NavLink, Text} from "@mantine/core";
import {
  IconAbacus,
  IconAddressBook, IconApiApp,
  IconArrowBigDownLine,
  IconArrowBigUpLine, IconArrowsDownUp,
  IconArrowsShuffle, IconBook,
  IconBuildingSkyscraper, IconCalculator,
  IconCoffee,
  IconCoins,
  IconFileStack,
  IconGauge,
  IconReceipt, IconReceiptTax, IconReportMoney,
  IconRuler2, IconScale,
  IconSettings
} from "@tabler/icons-react";
import {useLocation, useParams} from "react-router-dom";
import React from "react";

export const PrivateShellNavbar = () => {
  const location = useLocation();
  const params = useParams();
  const organisationId = params['organisationId'];

  const orgLinks = [
    {
      label: 'Dashboard',
      path: `/app/organisation/${organisationId}`,
      icon: <IconGauge size={25} stroke={1.5}/>
    },
    {
      label: 'Documents',
      path: `/app/organisation/${organisationId}/documents`,
      icon: <IconApiApp size={25} stroke={1.5}/>,
      subLinks: [
        {
          label: 'Incoming Invoices',
          path: `/app/organisation/${organisationId}/documents/incoming-invoices`,
          icon: <IconReceipt size={25} stroke={1.5}/>,
        },
        {
          label: 'Register Reports',
          path: `/app/organisation/${organisationId}/documents/register-reports`,
          icon: <IconReportMoney size={25} stroke={1.5}/>,
          disabled: false
        },
      ]
    },
    {
      label: 'Files',
      path: `/app/organisation/${organisationId}/files`,
      icon: <IconFileStack size={25} stroke={1.5}/>
    },
    {
      label: 'Ledger',
      path: `/app/organisation/${organisationId}/ledger`,
      icon: <IconBook size={25} stroke={1.5}/>,
      subLinks: [
        {
          label: 'Balances',
          path: `/app/organisation/${organisationId}/ledger/balances`,
          icon: <IconScale size={25} stroke={1.5}/>,
        },
        {
          label: 'Transactions',
          path: `/app/organisation/${organisationId}/ledger/transactions`,
          icon: <IconAbacus size={25} stroke={1.5}/>,
          disabled: false
        },
      ]
    },
    {
      label: 'Settings',
      path: `/app/organisation/${organisationId}/settings`,
      icon: <IconSettings size={25} stroke={1.5}/>,
      subLinks: [
        {
          label: 'Items',
          path: `/app/organisation/${organisationId}/settings/items`,
          icon: <IconCoffee size={25} stroke={1.5}/>
        },
        {
          label: 'Contacts',
          path: `/app/organisation/${organisationId}/settings/contacts`,
          icon: <IconAddressBook size={25} stroke={1.5}/>
        },
        {
          label: 'Registers',
          path: `/app/organisation/${organisationId}/settings/registers`,
          icon: <IconCalculator size={25} stroke={1.5}/>
        },
        {
          label: 'Organisation',
          path: `/app/organisation/${organisationId}/settings/organisation`,
          icon: <IconBuildingSkyscraper size={25} stroke={1.5}/>
        },
        {
          label: 'Taxes',
          path: `/app/organisation/${organisationId}/settings/taxes`,
          icon: <IconReceiptTax size={25} stroke={1.5}/>
        },
      ]
    },
  ]

  const userLinks = [
    {
      label: 'User Organisations',
      path: `/app`,
      icon: <IconBuildingSkyscraper size={25} stroke={1.5}/>
    },
    {
      label: 'User Settings',
      path: `/app/settings`,
      icon: <IconSettings size={25} stroke={1.5}/>
    }
  ]

  return <>
    <Text size="sm" style={{paddingLeft: "10px", paddingTop: '10px'}}>Organisation</Text>

    {orgLinks.map((link, idx) => (
      <NavLink key={idx}
               label={link.label}
               href={link.subLinks ? '' : link.path}
               leftSection={link.icon}
               active={location.pathname === link.path}
               defaultOpened={location.pathname.startsWith(link.path)}
        // disabled={!organisationId || link.disabled}
               disabled={!organisationId}
      >
        {link.subLinks?.map((link, idx) => (
          <NavLink key={idx}
                   label={link.label}
                   href={link.path}
                   leftSection={link.icon}
                   active={location.pathname === link.path}
                   disabled={!organisationId || link.disabled}
          />
        ))}
      </NavLink>
    ))}

    <Divider/>

    <Text size="sm" style={{paddingLeft: "10px", paddingTop: '15px'}}>User</Text>

    {userLinks.map((link, idx) => (
      <NavLink key={idx}
               label={link.label}
               href={link.path}
               leftSection={link.icon}
               active={location.pathname === link.path}
      />
    ))}

  </>;
}