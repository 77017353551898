export interface JwtToken {
  sub: string   // Subject
  typ: string   // Type
  iat: number     // Issued At (seconds)
  exp: number     // Expires At (seconds)
  rol: String[] // Roles
}

export class JwtTokenUtils {

  static decodeJwtToken(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window.atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload) as JwtToken;
  }

  static tokenHasExpired(token: string) {
    const decodedToken = JwtTokenUtils.decodeJwtToken(token);
    const tokenExpiresAt = decodedToken.exp;
    const epochSecondsNow = Date.now() / 1000;
    return tokenExpiresAt < epochSecondsNow;
  }
}