import {Flex, Grid, Text} from "@mantine/core";
import {StatusBadge} from "../../../../../common/StatusBadge";
import {ContactSelect} from "../../../../../common/select/ContactSelect";
import {TextInput} from "../../../../../common/TextInput";
import {DateInputV2} from "../../../../../common/DateInputV2";
import React from "react";
import {ApiContact, ApiInvoice} from "../../../../../utils/http/apiClient";

interface Props {
  invoice: ApiInvoice
  setInvoice: (invoice: ApiInvoice) => void
  contacts: ApiContact[]
  fetchEntities: () => void
}

export const IncomingInvoiceFieldGrid = ({invoice, setInvoice, contacts, fetchEntities}: Props) => {
  return <Flex direction={"row"} gap="md" style={{width: '100%'}}>
    <Grid style={{width: '23rem'}} gutter="sm">
      <Grid.Col span={4}>
        <Text size="sm">Status: </Text>
      </Grid.Col>

      <Grid.Col span={8}>
        <StatusBadge status={invoice.bookingStatus ?? 'CREATING'}/>
      </Grid.Col>

      <Grid.Col span={4}>
        <Text size="sm">Supplier: </Text>
      </Grid.Col>
      <Grid.Col span={8}>
        <ContactSelect inputLabel="Customer"
                       contactId={invoice.objectId ?? undefined}
                       contacts={contacts}
                       onChange={contactId => setInvoice({...invoice, objectId: contactId ?? null})}
                       onNewCreated={fetchEntities}/>
      </Grid.Col>

      <Grid.Col span={4}>
        <Text size="sm">Invoice number: </Text>
      </Grid.Col>

      <Grid.Col span={8}>
        <TextInput label="Invoice Number"
                   value={invoice.invoiceNumber}
                   onChange={value => setInvoice({...invoice, invoiceNumber: value})}/>
      </Grid.Col>

    </Grid>

    <Grid style={{width: '14rem'}} gutter="sm">

      <Grid.Col span={5}>
        <Text size="sm">Issue date: </Text>
      </Grid.Col>
      <Grid.Col span={7}>
        <DateInputV2 value={invoice.issueDate} onChange={date => setInvoice({...invoice, issueDate: date})}/>
      </Grid.Col>

      <Grid.Col span={5}>
        <Text size="sm">Due date: </Text>
      </Grid.Col>
      <Grid.Col span={7}>
        <DateInputV2 value={invoice.dueDate} onChange={date => setInvoice({...invoice, dueDate: date})}/>
      </Grid.Col>

      <Grid.Col span={5}>
        <Text size="sm">Booking date: </Text>
      </Grid.Col>
      <Grid.Col span={7}>
        <DateInputV2 value={invoice.bookingDate}
                     onChange={date => setInvoice({...invoice, bookingDate: date})}/>
      </Grid.Col>

    </Grid>
  </Flex>
}