import {Button, Flex, Modal, Switch, TextInput} from "@mantine/core";
import React, {FormEvent, useCallback, useImperativeHandle, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {ApiClient, ApiUnit, UpsertUnitRequest} from "../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import {IconCheck, IconCircleX} from "@tabler/icons-react";
import {notifyError, notifySavedChanges} from "../../../../utils/notificationUtils";
import {ModalTitle} from "../../../../common/ModalTitle";

interface Props {
  onSuccess?: (apiUnit: ApiUnit) => void
  onClose?: () => void
}

interface Unit {
  id?: string
  name: string
  shortName: string
  active: boolean
}

const mapApiUnit = (apiUnit?: ApiUnit) => {
  return {
    id: apiUnit?.id,
    name: apiUnit ? apiUnit.name : '',
    shortName: apiUnit ? apiUnit.shortName : '',
    active: apiUnit ? apiUnit.active : true,
  } as Unit;
}

export const UnitModal = React.forwardRef(({onSuccess, onClose}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const [unit, setUnit] = useState<Unit>(mapApiUnit(undefined));

  const openModal = useCallback((apiUnit?: ApiUnit) => {
    setUnit(mapApiUnit(apiUnit));
    open();
  }, [open]);

  const closeModal = useCallback(() => {
    setUnit(mapApiUnit(undefined))
    onClose?.();
    close();
  }, [setUnit, close]);

  useImperativeHandle(ref, () => ({openModal}));

  const handleSave = (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    setLoading(true);
    const request = {name: unit.name, shortName: unit.shortName, active: unit.active} as UpsertUnitRequest;
    (unit.id
        ? ApiClient.updateUnit(organisationId ?? '', unit.id, request)
        : ApiClient.createUnit(organisationId ?? '', request)
    )
      .then((resp) => {
        onSuccess?.(resp.data);
        closeModal();
        notifySavedChanges();
      })
      .catch(notifyError)
      .finally(() => setLoading(false));
  }

  return <>
    <Modal opened={opened}
           id="unit_modal"
           onClose={closeModal}
           title={<ModalTitle name="Unit" id={unit?.id}/>}
           closeOnClickOutside={false}
           returnFocus={true}
           transitionProps={{duration: 100}}
           overlayProps={{opacity: 0.5}}
    >
      <Flex align="center" direction="column" gap="md">
        <form style={{width: "100%"}} onSubmit={handleSave}>
          <TextInput placeholder="e.g. Kilogram"
                     label="Name"
                     data-autofocus
                     value={unit.name}
                     disabled={loading}
                     onChange={e => setUnit({...unit, name: e.target.value})}
          />
          <TextInput placeholder="e.g. KG"
                     label="Short name"
                     data-autofocus
                     value={unit.shortName}
                     disabled={loading}
                     onChange={e => setUnit({...unit, shortName: e.target.value})}
          />

          <Flex direction="row" justify="right" style={{width: "100%", paddingTop: '30px'}}>
            <Switch size={"sm"}
                    labelPosition="left"
                    label="Active"
                    style={{paddingTop: '5px'}}
                    checked={unit.active}
                    onChange={e => setUnit({...unit, active: e.target.checked})}
            />
          </Flex>

          <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
            <Button type="submit" rightSection={<IconCheck/>} loading={loading}>
              Save
            </Button>
            <Button variant="outline" rightSection={<IconCircleX/>} onClick={closeModal} disabled={loading}>
              Cancel
            </Button>
          </Flex>
        </form>
      </Flex>
    </Modal>
  </>;
});