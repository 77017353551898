import {NumberInput} from "@mantine/core";
import React, {useEffect, useState} from "react";

interface PercentageInputProps {
  value: number | null
  onChange: (value: number | null) => void
  label?: string
  placeholder?: string
  disabled?: boolean
}

export const PercentageInput = ({
                                        value,
                                        onChange,
                                        label = "",
                                        placeholder = "",
                                        disabled = false
                                      }: PercentageInputProps) => {

  const [internalValue, setInternalValue] = useState<number | string>(value ?? '');

  useEffect(() => {
    setInternalValue(value ?? '');
  }, [value]);

  const handleChange = (newValue: string | number) => {
    setInternalValue(newValue);
    if (typeof newValue === 'number') {
      onChange(newValue);
    } else {
      onChange(null);
    }
  }

  return <NumberInput label={label}
                      placeholder={placeholder}
                      allowDecimal={false}
                      suffix="%"
                      min={0}
                      max={100}
                      clampBehavior="strict"
                      value={internalValue}
                      disabled={disabled}
                      hideControls
                      onChange={handleChange}
  />;
}