import {DateInput as MantineDateInput, DateValue} from "@mantine/dates";
import React, {memo, useState} from "react";
import dayjs from "dayjs";
import {dateParser, toUTCDate} from "../utils/date/dateUtils";

export interface Props {
  value: Date | null
  onChange: (value: DateValue) => void
  onBlur?: () => void
  tabIndex?: number
}

export const DateInput = memo(({value, onChange, onBlur, tabIndex}: Props) => {
  return <>
    <MantineDateInput valueFormat="DD.MM.YYYY"
                      value={value}
                      onChange={(date) => onChange(toUTCDate(date))}
                      onBlur={() => onBlur?.()}
                      placeholder={dayjs().format('DD.MM.YYYY')}
                      dateParser={dateParser}
                      clearable={true}
                      highlightToday={true}
                      tabIndex={tabIndex}
                      styles={{
                        input: {
                          minHeight: '24px',
                          maxHeight: '24px',
                        }
                      }}
    />
  </>

});