import {Avatar, Box, Divider, Flex, NavLink, Popover, Select, Text, Title} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {IconHome2, IconLogout, IconSettings} from "@tabler/icons-react";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {ApiClient, ApiOrganisation, ApiUser} from "../../utils/http/apiClient";

export const PrivateShellHeader = () => {
  const params = useParams();
  const {auth, doLogout} = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState<ApiUser | undefined>(undefined);
  const [organisations, setOrganisations] = useState<ApiOrganisation[]>([]);

  const handleLogout = () => {
    doLogout().then(() => navigate('/login'));
  }

  const fetchUserAndOrganisations = () => {
    Promise.all([
      ApiClient.getCurrentUser(),
      ApiClient.getOrganisations()
    ]).then(([user, organisations]) => {
      setUser(user.data);
      setOrganisations(organisations.data);
    })
  };

  useEffect(() => {
    fetchUserAndOrganisations();
  }, [auth]);

  return <>
    <Flex justify="space-between" align="center" bg="blue.7" style={{height: "100%"}}>
      <Title order={1} style={{color: "white", marginLeft: '20px'}}>
        munita
      </Title>

      <Flex direction="row" align="center">
        <Text c="white" size="sm" style={{paddingRight: '5px'}}>Organisation</Text>
        <Select data={organisations.map(o => ({label: o.name, value: o.id}))}
                value={params?.organisationId}
                style={{paddingRight: '10px'}}
                allowDeselect={false}
                size="sm"
                onChange={orgId => {
                  if (orgId) {
                    navigate(`/app/organisation/${orgId}`);
                  }
                }}/>

        <Box style={{paddingRight: '10px'}}>
          <Popover position="bottom" width={250} shadow="md" offset={3}>
            <Popover.Target>
              <Avatar radius="xl" color="white" style={{cursor: "pointer", background: 'var(--mantine-color-blue-4)'}}/>
            </Popover.Target>
            <Popover.Dropdown style={{padding: 0}}>
              <Flex direction="column" align="center" style={{padding: "10px"}}>
                <Avatar radius="xl" size="lg"/>
                <Text size="lg">{user?.displayName}</Text>
                <Text size="sm">{user?.email}</Text>
              </Flex>
              <NavLink label="UserHome" leftSection={<IconHome2 size={24} stroke={1.5}/>}
                       href="/app"/>
              <NavLink label="UserSettings" leftSection={<IconSettings size={24} stroke={1.5}/>}
                       href="/app/settings"/>
              <Divider/>
              <NavLink label="Logout" leftSection={<IconLogout size={24} stroke={1.5}/>}
                       onClick={handleLogout}/>
            </Popover.Dropdown>
          </Popover>
        </Box>
      </Flex>
    </Flex>
  </>
};