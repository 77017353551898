export const formatDebitAmount = (amount: number) => amount >= 0 ? formatAmount(amount) : ''

export const formatCreditAmount = (amount: number) => {
  return amount >= 0 ? '' : formatAmount(-amount);
}

export const formatNumber = (amount: number) => {
  return amount.toLocaleString(undefined, {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4
  });
}

export const formatAmount = (amount: number) => {
  return amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

export const formatPercentage = (amount: number) => {
  const scaled = (amount * 100).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  return `${scaled}%`
}

export const capitalize = (word: string) => {
  return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
}