import {notifications} from "@mantine/notifications";
import {IconCircleCheckFilled, IconExclamationCircle} from "@tabler/icons-react";
import React from "react";
import {AxiosError} from "axios";
import {Flex, Text} from "@mantine/core";

export function notifySavedChanges() {
  notifications.show({
    message: "Saved changes",
    color: "green",
    icon: <IconCircleCheckFilled/>
  });
}

export function notifyError({message, response}: AxiosError) {
  const {errorMessage} = response?.data as { errorMessage?: String } || {};
  notifications.show({
    message: <Text size="sm">
      {errorMessage ?? message}
    </Text>,
    color: "red",
    icon: <IconExclamationCircle/>,
    autoClose: 4000
  });
}