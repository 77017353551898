import {Flex, Grid, Text} from "@mantine/core";
import {StatusBadge} from "../../../../../common/StatusBadge";
import {ContactSelect} from "../../../../../common/select/ContactSelect";
import {TextInput} from "../../../../../common/TextInput";
import {DateInputV2} from "../../../../../common/DateInputV2";
import React from "react";
import {ApiContact, ApiInvoice, ApiRegister} from "../../../../../utils/http/apiClient";
import {RegisterSelect} from "../../../../../common/select/RegisterSelect";

interface Props {
  invoice: ApiInvoice
  setInvoice: (invoice: ApiInvoice) => void
  registers: ApiRegister[]
  fetchEntities: () => void
}

export const RegisterReportFieldGrid = ({invoice, setInvoice, registers, fetchEntities}: Props) => {
  return <Flex direction={"row"} gap="md" style={{width: '100%'}}>
    <Grid style={{width: '23rem'}} gutter="sm">
      <Grid.Col span={4}>
        <Text size="sm">Status: </Text>
      </Grid.Col>

      <Grid.Col span={8}>
        <StatusBadge status={invoice.bookingStatus ?? 'CREATING'}/>
      </Grid.Col>

      <Grid.Col span={4}>
        <Text size="sm">Register: </Text>
      </Grid.Col>
      <Grid.Col span={8}>
        <RegisterSelect inputLabel="Register"
                        registerId={invoice.objectId ?? undefined}
                        registers={registers}
                        onChange={registerId => setInvoice({
                          ...invoice,
                          objectId: registerId ?? null
                        })}
                        onNewCreated={fetchEntities}/>
      </Grid.Col>

    </Grid>

    <Grid style={{width: '14rem'}} gutter="sm">

      <Grid.Col span={5}>
        <Text size="sm">Issue date: </Text>
      </Grid.Col>
      <Grid.Col span={7}>
        <DateInputV2 value={invoice.issueDate} onChange={date => setInvoice({...invoice, issueDate: date})}/>
      </Grid.Col>

      <Grid.Col span={5}>
        <Text size="sm">Booking date: </Text>
      </Grid.Col>
      <Grid.Col span={7}>
        <DateInputV2 value={invoice.bookingDate}
                     onChange={date => setInvoice({...invoice, bookingDate: date})}/>
      </Grid.Col>
    </Grid>
  </Flex>
}