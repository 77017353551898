import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import {
  ApiClient,
  ApiContact,
  ApiInvoice,
  ApiInvoiceLineItem,
  ApiInvoiceTaxItem,
  ApiItem,
  ApiRegister,
  ApiTax,
  ApiUnit,
  ApiUpsertInvoiceLineItemRequest,
  ApiUpsertInvoiceRequest,
  BookingStatus,
  InvoiceType
} from "../../../../utils/http/apiClient";
import {Button, Center, Flex, Grid, LoadingOverlay, Modal, Space} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import {createIdMap, nonEmptyObject, roundAmount, roundToX} from "../../../../utils/objectUtils";
import {notifyError, notifySavedChanges} from "../../../../utils/notificationUtils";
import {IncomingInvoiceFieldGrid} from "./fieldGrid/IncomingInvoiceFieldGrid";
import {RegisterReportFieldGrid} from "./fieldGrid/RegisterReportFieldGrid";
import {formatNumber} from "../../../../utils/formatUtils";
import {DataSheet} from "../../../../common/datasheet/DataSheet";
import {Option} from "../../../../common/datasheet/DataSheetSelect";
import {ActionButton} from "../../../../common/actionButton/ActionButton";
import {IconArrowLeft, IconBook, IconCircleX, IconDeviceFloppy, IconTrashX} from "@tabler/icons-react";
import {StatusBadge} from "../../../../common/StatusBadge";
import {ModalTitle} from "../../../../common/ModalTitle";

interface Props {
  onSuccess: () => void
}

interface Totals {
  netAmount: number | null
  taxAmount: number | null
  grossAmount: number | null

  [key: string]: any
}

const emptyInvoice = () => {
  return {
    lineItems: [...Array(3)].map(_ => ({} as ApiInvoiceLineItem)),
    taxItems: [...Array(3)].map(_ => ({} as ApiInvoiceTaxItem)),
  } as ApiInvoice;
}

export const InvoiceModal = React.forwardRef(({onSuccess}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [invoiceType, setInvoiceType] = useState<InvoiceType | undefined>(undefined);
  const [invoiceId, setInvoiceId] = useState<string | undefined>(undefined);
  const [opened, {open, close}] = useDisclosure(false);
  const [loading, setLoading] = useState(false);

  const [invoice, setInvoice] = useState<ApiInvoice>(emptyInvoice());
  const [contacts, setContacts] = useState<ApiContact[]>([]);
  const [registers, setRegisters] = useState<ApiRegister[]>([]);
  const [items, setItems] = useState<ApiItem[]>([]);
  const [taxes, setTaxes] = useState<ApiTax[]>([]);
  const [units, setUnits] = useState<ApiUnit[]>([]);
  const [itemsById, setItemsById] = useState<Record<string, ApiItem>>({});
  const [unitsById, setUnitsById] = useState<Record<string, ApiUnit>>({});

  const [computedTaxItems, setComputedTaxItems] = useState<ApiInvoiceTaxItem[]>([]);
  const [computedTotals, setComputedTotals] = useState([{
    name: 'Total',
    netAmount: 0,
    taxAmount: 0,
    grossAmount: 0
  } as Totals]);

  const modalRefs = {
    item: useRef<any>(),
    tax: useRef<any>(),
  };

  const openModal = useCallback((invoiceType: InvoiceType, invoiceId?: string) => {
    setInvoiceType(invoiceType);
    setInvoiceId(invoiceId);
    if (invoiceId === undefined) {
      setInvoice(emptyInvoice());
    }
    open();
  }, [open]);

  const closeModal = useCallback((success: boolean) => {
    if (success) {
      onSuccess();
    }
    setInvoiceId(undefined);
    close();
  }, [close]);

  useImperativeHandle(ref, () => ({openModal}));

  const fetchEntities = () => {
    if (organisationId && invoiceType) {
      console.log("fetchEntities");
      setLoading(true);
      return Promise.all([
        invoiceId
          ? ApiClient.getInvoice(organisationId, invoiceType, invoiceId).then(resp => resp.data)
          : Promise.resolve(emptyInvoice()),
        ApiClient.getTaxes(organisationId).then(resp => resp.data),
        ApiClient.getItems(organisationId).then(resp => resp.data),
        ApiClient.getContacts(organisationId).then(resp => resp.data),
        ApiClient.getRegisters(organisationId).then(resp => resp.data),
        ApiClient.getUnits(organisationId).then(resp => resp.data)
      ]).then(([invoice, taxes, items, contacts, registers, units]) => {
        setInvoice({
          ...invoice,
          lineItems: invoice?.lineItems?.length > 0
            ? invoice.lineItems
            : [...Array(3)].map(_ => ({} as ApiInvoiceLineItem)),
          taxItems: invoice?.taxItems?.length > 0
            ? invoice.taxItems
            : [...Array(3)].map(_ => ({} as ApiInvoiceTaxItem)),
        })
        setTaxes(taxes);
        setItems(items);
        setContacts(contacts);
        setRegisters(registers);
        setUnits(units);
        setItemsById(createIdMap(items));
        setUnitsById(createIdMap(units));
      }).catch(notifyError)
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchEntities();
  }, [organisationId, invoiceId]);

  const [itemOptions, setItemOptions] = useState<Option[]>([]);
  const [taxOptions, setTaxOptions] = useState<Option[]>([]);

  useEffect(() => {
    setItemOptions(items.map(i => ({value: i.id, label: i.name} as Option)));
    setTaxOptions(taxes.map(t => ({value: t.id, label: `${t.rate * 100}%`} as Option)));
  }, [items, units, taxes])

  const [totals, setTotals] = useState([{
    name: 'Total',
    netAmount: null,
    taxAmount: null,
    grossAmount: null
  } as Totals]);
  useEffect(() => {
    setTotals([{
      name: 'Total',
      netAmount: invoice.netAmount,
      taxAmount: invoice.taxAmount,
      grossAmount: invoice.grossAmount,
    }]);
  }, [invoice]);

  const mapToLineItemRequest = (lineItem: ApiInvoiceLineItem) => {
    return {
      itemId: lineItem.itemId,
      taxId: lineItem.taxId,
      text: lineItem.text,
      quantity: lineItem.quantity,
      netAmount: lineItem.netAmount,
      taxAmount: lineItem.taxAmount,
      grossAmount: lineItem.grossAmount,
    } as ApiUpsertInvoiceLineItemRequest;
  }

  const mapToTaxItemRequest = (taxItem: ApiInvoiceTaxItem) => {
    return {
      taxId: taxItem.taxId,
      netAmount: taxItem.netAmount,
      taxAmount: taxItem.taxAmount,
      grossAmount: taxItem.grossAmount,
    } as ApiInvoiceTaxItem;
  }

  const handleSave = (bookingStatus: BookingStatus) => {
    if (organisationId && invoiceType) {
      setLoading(true);
      const request = {
        ...invoice,
        bookingStatus: bookingStatus,
        lineItems: invoice.lineItems.map(mapToLineItemRequest).filter(nonEmptyObject),
        taxItems: invoice.taxItems.map(mapToTaxItemRequest).filter(nonEmptyObject),
      } as ApiUpsertInvoiceRequest;
      (invoice.id
          ? ApiClient.updateInvoice(organisationId, invoiceType, invoice.id, request)
          : ApiClient.createInvoice(organisationId, invoiceType, request)
      )
        .then(() => closeModal(true))
        .then(notifySavedChanges)
        .catch(notifyError)
        .finally(() => setLoading(false));
    }
  }

  useEffect(() => {
    const netAmounts: Record<string, number> = {};
    const taxAmounts: Record<string, number> = {};
    const grossAmounts: Record<string, number> = {};

    invoice.lineItems.forEach(li => {
      const taxId = li.taxId ?? 'NaN';
      netAmounts[taxId] = (netAmounts[taxId] ?? 0) + Number(li.netAmount);
      taxAmounts[taxId] = (taxAmounts[taxId] ?? 0) + Number(li.taxAmount);
      grossAmounts[taxId] = (grossAmounts[taxId] ?? 0) + Number(li.grossAmount);
    });

    const taxIds = Object.keys(netAmounts);
    const taxItems = Array.from(taxIds).map(taxId => ({
      taxId: taxId,
      netAmount: roundAmount(netAmounts[taxId]),
      taxAmount: roundAmount(taxAmounts[taxId]),
      grossAmount: roundAmount(grossAmounts[taxId]),
    } as ApiInvoiceTaxItem));

    setComputedTaxItems(taxItems);
    setComputedTotals([{
      name: 'Total',
      netAmount: roundAmount(taxItems.map(ti => ti.netAmount ?? 0).reduce((acc, curr) => acc + curr, 0)),
      taxAmount: roundAmount(taxItems.map(ti => ti.taxAmount ?? 0).reduce((acc, curr) => acc + curr, 0)),
      grossAmount: roundAmount(taxItems.map(ti => ti.grossAmount ?? 0).reduce((acc, curr) => acc + curr, 0)),
    }]);
  }, [invoice.lineItems, invoice.taxItems]);

  const copyCalculatedTaxItems = () => {
    const copiedTaxItems = computedTaxItems.map(taxItem => ({
      taxId: taxItem.taxId,
      netAmount: taxItem.netAmount,
      taxAmount: taxItem.taxAmount,
      grossAmount: taxItem.grossAmount,
    } as ApiInvoiceTaxItem));
    setInvoice({...invoice, taxItems: copiedTaxItems});
  }

  const copyCalculatedTotals = () => {
    setInvoice({
      ...invoice,
      netAmount: computedTotals[0].netAmount,
      taxAmount: computedTotals[0].taxAmount,
      grossAmount: computedTotals[0].grossAmount,
    })
  }

  const titleMap = {
    'INCOMING_INVOICE': 'Incoming Invoice',
    'REGISTER_REPORT': 'Register report'
  } as Record<InvoiceType, string>;

  return <>
    <LoadingOverlay visible={loading}/>
    <Modal opened={opened}
           onClose={() => closeModal(false)}
           title={<ModalTitle name={titleMap[invoiceType ?? 'INCOMING_INVOICE']} id={invoiceId}/>}
           closeOnClickOutside={false}
           transitionProps={{duration: 100}}
           overlayProps={{opacity: 0.5}}
           size="auto"
    >
      {invoiceType === 'INCOMING_INVOICE'
        ? <IncomingInvoiceFieldGrid invoice={invoice} setInvoice={setInvoice} contacts={contacts}
                                    fetchEntities={fetchEntities}/>
        : <></>}

      {invoiceType === 'REGISTER_REPORT'
        ? <RegisterReportFieldGrid invoice={invoice} setInvoice={setInvoice} registers={registers}
                                   fetchEntities={fetchEntities}/>
        : <></>}

      <Space h="1rem"/>

      <DataSheet
        caption="Line items"
        noWrap={true}
        columns={[
          {
            name: 'text',
            displayName: 'Text',
            type: 'TEXT',
            align: 'left'
          },
          {
            name: 'itemId',
            displayName: 'Item',
            type: 'SELECT',
            align: 'left',
            selectOptions: itemOptions,
            modalRef: modalRefs.item,
          },
          {
            name: 'unit',
            displayName: 'Unit',
            type: 'DERIVED',
            align: 'center',
            deriveFunction: (row) => {
              const item = itemsById[invoice.lineItems[row].itemId ?? ''];
              const unit = unitsById[item?.unitId ?? ''];
              return unit?.shortName ?? '';
            }
          },
          {
            name: 'unitPrice',
            displayName: 'Unit Price',
            type: 'DERIVED',
            align: 'right',
            deriveFunction: (row) => {
              const netAmount = invoice.lineItems[row].netAmount;
              const quantity = invoice.lineItems[row].quantity;
              return (netAmount && netAmount !== 0 && quantity && quantity !== 0)
                ? formatNumber(roundToX(netAmount / quantity, 4))
                : '';
            }
          },
          {
            name: 'quantity',
            displayName: 'Quantity',
            type: 'NUMBER',
            align: 'right'
          },
          {
            name: 'taxId',
            displayName: 'Tax',
            type: 'SELECT',
            align: 'right',
            selectOptions: taxOptions,
            modalRef: modalRefs.tax,
          },
          {
            name: 'netAmount',
            displayName: 'Net Amount',
            type: 'AMOUNT',
            align: 'right'
          },
          {
            name: 'taxAmount',
            displayName: 'Tax Amount',
            type: 'AMOUNT',
            align: 'right'
          },
          {
            name: 'grossAmount',
            displayName: 'Gross Amount',
            type: 'AMOUNT',
            align: 'right'
          },
        ]}
        rows={invoice.lineItems}
        updateRows={(rows) => {
          const newLineItems = rows.map(row => {
            const lineItem = {} as ApiInvoiceLineItem;
            Object.keys(row).forEach(col => lineItem[col] = row[col])
            return lineItem;
          });
          setInvoice({...invoice, lineItems: newLineItems});
        }}
        rowErrorCheckers={[]}/>

      <Space h="20"/>

      <div style={{minWidth: '900px', width: '100%'}}>
        <Grid align="center" justify="space-between">
          <Grid.Col span={5}>
            <Center>
              <DataSheet
                caption="VAT items"
                noWrap={true}
                columns={[
                  {name: 'taxId', displayName: 'Tax', type: 'SELECT', align: 'right', selectOptions: taxOptions},
                  {name: 'netAmount', displayName: 'Net Amount', type: 'AMOUNT', align: 'right'},
                  {name: 'taxAmount', displayName: 'Tax Amount', type: 'AMOUNT', align: 'right'},
                  {name: 'grossAmount', displayName: 'Gross Amount', type: 'AMOUNT', align: 'right'},
                ]}
                rows={invoice.taxItems}
                updateRows={(rows) => {
                  const newTaxItems = rows.map(row => {
                    const taxItem = {} as ApiInvoiceTaxItem;
                    Object.keys(row).forEach(col => taxItem[col] = row[col])
                    return taxItem;
                  });
                  setInvoice({...invoice, taxItems: newTaxItems});
                }}
                rowErrorCheckers={[]}/>
            </Center>
          </Grid.Col>

          <Grid.Col span={2}>
            <Center>
              <Button variant={"light"} size={"xs"} leftSection={<IconArrowLeft/>}
                      onClick={copyCalculatedTaxItems}>
                Copy
              </Button>
            </Center>
          </Grid.Col>

          <Grid.Col span={5}>
            <Center>
              <DataSheet
                caption={"Calculated VAT summary"}
                noWrap={true}
                columns={[
                  {
                    name: 'taxId',
                    displayName: 'Tax',
                    type: 'SELECT',
                    align: 'right',
                    selectOptions: taxOptions,
                    readOnly: true
                  },
                  {name: 'netAmount', displayName: 'Net Amount', type: 'AMOUNT', align: 'right', readOnly: true},
                  {name: 'taxAmount', displayName: 'Tax Amount', type: 'AMOUNT', align: 'right', readOnly: true},
                  {
                    name: 'grossAmount',
                    displayName: 'Gross Amount',
                    type: 'AMOUNT',
                    align: 'right',
                    readOnly: true
                  },
                ]}
                rows={computedTaxItems}
                updateRows={() => null}
                rowErrorCheckers={[]}
              />
            </Center>
          </Grid.Col>
        </Grid>

        <Grid align="center" justify="space-between">
          <Grid.Col span={5}>
            <Center>
              <DataSheet
                caption={"Totals"}
                noWrap={true}
                columns={[
                  {name: 'name', displayName: 'Name', type: 'TEXT', align: 'right', readOnly: true},
                  {name: 'netAmount', displayName: 'Net Amount', type: 'AMOUNT', align: 'right'},
                  {name: 'taxAmount', displayName: 'Tax Amount', type: 'AMOUNT', align: 'right'},
                  {name: 'grossAmount', displayName: 'Gross Amount', type: 'AMOUNT', align: 'right'},
                ]}
                rows={totals}
                updateRows={(rows) => {
                  setInvoice({
                    ...invoice,
                    netAmount: rows[0].netAmount !== null ? Number(rows[0].netAmount) : null,
                    taxAmount: rows[0].taxAmount !== null ? Number(rows[0].taxAmount) : null,
                    grossAmount: rows[0].grossAmount !== null ? Number(rows[0].grossAmount) : null
                  });
                }}
                rowErrorCheckers={[]}
              />
            </Center>
          </Grid.Col>

          <Grid.Col span={2}>
            <Center>
              <Button variant={"light"} size={"xs"} leftSection={<IconArrowLeft/>} onClick={copyCalculatedTotals}>
                Copy
              </Button>
            </Center>
          </Grid.Col>

          <Grid.Col span={5}>
            <Center>
              <DataSheet
                caption={"Calculated Totals"}
                noWrap={true}
                columns={[
                  {name: 'name', displayName: 'Name', type: 'TEXT', align: 'right', readOnly: true},
                  {name: 'netAmount', displayName: 'Net Amount', type: 'AMOUNT', align: 'right', readOnly: true},
                  {name: 'taxAmount', displayName: 'Tax Amount', type: 'AMOUNT', align: 'right', readOnly: true},
                  {
                    name: 'grossAmount',
                    displayName: 'Gross Amount',
                    type: 'AMOUNT',
                    align: 'right',
                    readOnly: true
                  },
                ]}
                rows={computedTotals}
                updateRows={() => null}
                rowErrorCheckers={[]}
              />
            </Center>
          </Grid.Col>
        </Grid>
      </div>


      <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
        <ActionButton
          defaultAction={
            {
              label: "Save as Draft",
              action: () => handleSave('DRAFT'),
              leftSection: <IconDeviceFloppy size="1.5rem" stroke={1.5}/>,
              disabled: invoice?.bookingStatus !== 'DRAFT'
            }
          }
          options={[
            {
              label: <>Save and book as <StatusBadge status="PARTIAL"/></>,
              action: () => handleSave('PARTIAL'),
              leftSection: <IconBook size="1.5rem" stroke={1.5}/>,
              disabled: invoice?.bookingStatus === 'PARTIAL'
            },
            {
              label: <>Save and book as <StatusBadge status="BOOKED"/></>,
              action: () => handleSave('BOOKED'),
              leftSection: <IconBook size="1.5rem" stroke={1.5}/>,
              disabled: invoice?.bookingStatus === 'BOOKED'
            },
            'DIVIDER',
            {
              label: 'Delete',
              action: () => null,
              leftSection: <IconTrashX size="1.5rem" stroke={1.5} color={'red'}/>,
              disabled: invoice?.bookingStatus === undefined
            },
          ]}
        />
        <Button variant="outline" rightSection={<IconCircleX/>} onClick={() => closeModal(false)} disabled={loading}>
          Cancel
        </Button>
      </Flex>
    </Modal>
  </>
});