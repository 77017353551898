import {NumberInput} from "@mantine/core";
import React, {KeyboardEvent as ReactKeyboardEvent, useEffect, useState} from "react";
import {ChangeCause, mapChangeCause} from "./DataSheet";

interface Props {
  value: number | null
  onChange: (value: number | null, cause: ChangeCause) => void
  defaultValue?: number
}

export const DataSheetPercentInput = ({
                                        value,
                                        onChange,
                                        defaultValue
                                      }: Props) => {

  const [internalValue, setInternalValue] = useState<number | string>(value ?? '');

  useEffect(() => {
    setInternalValue(value ?? '');
  }, [value]);

  const handleChange = (newValue: string | number) => {
    setInternalValue(newValue);
  }

  const handleSubmit = (cause: ChangeCause) => {
    if (typeof internalValue === 'number') {
      onChange(internalValue / 100, cause);
    } else {
      onChange(null, cause);
    }
  }

  const handleKeyDown = (event: ReactKeyboardEvent<HTMLInputElement>) => {
    const cause = mapChangeCause(event.key);
    if (cause !== 'Unknown') {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(event.key as ChangeCause);
    }
  }

  return <NumberInput allowDecimal={false}
                      min={0}
                      max={100}
                      clampBehavior="strict"
                      value={internalValue}
                      defaultValue={defaultValue}
                      hideControls
                      autoFocus
                      onClick={e => e.stopPropagation()}
                      onMouseDown={e => e.stopPropagation()}
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
  />;
}