import {Dialog, Divider, NavLink} from "@mantine/core";
import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
  Icon123,
  IconClipboardText,
  IconCopy,
  IconCut,
  IconNumber,
  IconPlus,
  IconReplace,
  IconTrash
} from "@tabler/icons-react";
import {DataSheetSearchAndReplaceModal} from "./DataSheetSearchAndReplaceModal";
import {CellType} from "./DataSheet";
import {isNumeric} from "../../pages/private/organisation/files/extraction/extractionsUtils";
import {formatNumber} from "../../utils/formatUtils";

export interface SuccessResponse {
  selectedMenuItem: string
  row: number
  col: string
  searchValue?: string
  replaceValue?: string,
  copyValue?: string
}

interface Props {
  onSuccess: (response: SuccessResponse) => void
}

interface CellContext {
  row: number
  col: string
  cellType: CellType
  left: number
  top: number
  selectedSum?: number
}

export const DataSheetContextMenu = React.forwardRef(({onSuccess}: Props, ref) => {
  const [open, setOpen] = useState(false);
  const [context, setContext] = useState<CellContext | undefined>();
  const searchAndReplaceModal = useRef<any>();

  const openMenu = useCallback((context: CellContext) => {
    setContext(context);
    setOpen(true);
  }, [open]);

  useImperativeHandle(ref, () => ({openMenu}));

  useEffect(() => {
    const handleClick = () => setOpen(false);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const handleClick = (selectedMenuItem: string) => {
    onSuccess({
      selectedMenuItem,
      row: context?.row,
      col: context?.col,
    } as SuccessResponse);
    setOpen(false);
  }

  const handleCopySelectedSum = () => {
    onSuccess({
      selectedMenuItem: 'COPY_SELECTED_SUM',
      row: context?.row,
      col: context?.col,
      copyValue: (context?.selectedSum ?? '').toString()
    } as SuccessResponse);
    setOpen(false);
  }

  const handleSearchAndReplaceClick = (searchValue: string, replaceValue: string) => {
    onSuccess({
      selectedMenuItem: 'SEARCH_AND_REPLACE',
      row: context?.row,
      col: context?.col,
      searchValue,
      replaceValue,
    } as SuccessResponse);
    setOpen(false);
  }

  const left = (context?.left ?? 0);
  const top = (context?.top ?? 0);
  const dialogWidth = 340;
  const dialogHeight = 300;
  const position = {
    left: left + dialogWidth <= window.innerWidth ? left : undefined,
    right: left + dialogWidth <= window.innerWidth ? undefined : window.innerWidth - left,
    top: top + dialogHeight <= window.innerHeight ? top : undefined,
    bottom: top + dialogHeight <= window.innerHeight ? undefined : window.innerHeight - top,
  };

  const copySumEnabled = context?.selectedSum && !isNaN(context?.selectedSum);

  return <>
    <Dialog opened={open}
            position={position}
            transitionProps={{transition: 'pop', duration: 0}}
            className={"datasheet-menu"}
    >
      <NavLink label="Cut" onClick={() => handleClick("CUT")} disabled={context?.row === -1}
               leftSection={<IconCut size="1rem" stroke={1.5}/>}/>
      <NavLink label="Copy" onClick={() => handleClick("COPY")} disabled={context?.row === -1}
               leftSection={<IconCopy size="1rem" stroke={1.5}/>}/>
      <NavLink label={`Copy sum: ${context?.selectedSum}`} onClick={handleCopySelectedSum}
               disabled={context?.row === -1 || !copySumEnabled}
               leftSection={<IconCopy size="1rem" stroke={1.5}/>}/>
      <NavLink label="Paste" onClick={() => handleClick("PASTE")} disabled={context?.row === -1}
               leftSection={<IconClipboardText size="1rem" stroke={1.5}/>}/>
      <Divider/>
      <NavLink label="Search and replace" onClick={() => searchAndReplaceModal.current?.openModal()}
               disabled={context?.row === -1 || context?.cellType !== 'TEXT'}
               leftSection={<IconReplace size="1rem" stroke={1.5}/>}/>
      <NavLink label="Format as number" onClick={() => searchAndReplaceModal.current?.openModal()}
               disabled={context?.row === -1 || context?.cellType !== 'TEXT'}
               leftSection={<Icon123 size="1rem" stroke={1.5}/>}/>
      <Divider/>
      <NavLink label="Insert Row above" onClick={() => handleClick("INSERT_ROW_ABOVE")} disabled={context?.row === -1}
               leftSection={<IconPlus size="1rem" stroke={1.5}/>}/>
      <NavLink label="Insert Row below" onClick={() => handleClick("INSERT_ROW_BELOW")}
               leftSection={<IconPlus size="1rem" stroke={1.5}/>}/>
      <NavLink label="Delete row(s)" onClick={() => handleClick("DELETE_ROW")} disabled={context?.row === -1}
               leftSection={<IconTrash size="1rem" stroke={1.5}/>}/>
    </Dialog>

    <DataSheetSearchAndReplaceModal ref={searchAndReplaceModal} onSuccess={handleSearchAndReplaceClick}/>
  </>
});