import React from "react";
import {Badge, Flex, Text} from "@mantine/core";
import {
  IconFlipHorizontal,
  IconFlipVertical, IconRotate,
  IconRotate2,
  IconRotateClockwise,
  IconRotateClockwise2
} from "@tabler/icons-react";
import {ImageAction} from "../../../../utils/http/apiClient";
import {ImageActionButton} from "../../../../common/ImageActionButton";


interface PageControlProps {
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
  onImageAction?: (action: ImageAction) => void
}

export const PageControl = ({pageCount, currentPage, onPageChange, onImageAction}: PageControlProps) => {
  return <Flex direction="row" justify="right" align="center">

    <Text size="sm" style={{paddingRight: '0.5rem'}}>Actions: </Text>
    <ImageActionButton toolTip={"Rotate image 90 degrees counter clockwise"}
                       icon={<IconRotate size="2rem" stroke={1.5}/>}
                       onClick={() => onImageAction?.("ROTATE_90_CCW")}/>
    <ImageActionButton toolTip={"Rotate image 90 degrees clockwise"}
                       icon={<IconRotateClockwise size="2rem" stroke={1.5}/>}
                       onClick={() => onImageAction?.("ROTATE_90_CW")}/>
    <ImageActionButton toolTip={"Rotate image 1 degree counter clockwise"}
                       icon={<IconRotate2 size="2rem" stroke={1.5}/>}
                       onClick={() => onImageAction?.("ROTATE_1_CCW")}/>
    <ImageActionButton toolTip={"Rotate image 1 degree clockwise"}
                       icon={<IconRotateClockwise2 size="2rem" stroke={1.5}/>}
                       onClick={() => onImageAction?.("ROTATE_1_CW")}/>
    <ImageActionButton toolTip={"Flip image vertically"}
                       icon={<IconFlipHorizontal size="2rem" stroke={1.5}/>}
                       onClick={() => onImageAction?.("FLIP_VERTICAL")}/>
    <ImageActionButton toolTip={"Flip image horizontally"}
                       icon={<IconFlipVertical size="2rem" stroke={1.5}/>}
                       onClick={() => onImageAction?.("FLIP_HORIZONTAL")}/>

    <Text size="sm" style={{paddingLeft: '1rem', paddingRight: '0.5rem'}}>Pages: </Text>
    {[...Array(pageCount)].map((_, idx) => (
      <Badge key={idx}
             onClick={() => onPageChange(idx)}
             size="md"
             variant={idx === currentPage ? 'filled' : 'default'}
             style={{cursor: 'pointer'}}
      >
        {idx + 1}
      </Badge>
    ))}
  </Flex>;
}