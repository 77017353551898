import {Select} from "@mantine/core";
import React, {useRef} from "react";
import {ApiItemCategory} from "../../utils/http/apiClient";
import {CategoryModal} from "../../pages/private/organisation/items/CategoryModal";

export interface Props {
  categoryId?: string
  categories: ApiItemCategory[],
  onChange: (value?: string) => void
  onNewCreated: (newUnit: ApiItemCategory) => void
  label?: string
  onBlur?: () => void
}

export const CategorySelect = ({
                                 categoryId,
                                 categories,
                                 onChange,
                                 onNewCreated,
                                 label,
                                 onBlur
                               }: Props) => {
  const modalRef = useRef<any>();

  const options = [{label: "+ Create new", value: "CREATE"}]
  options.push(
    ...categories.filter(category => category.active || categoryId === category.id)
      .map(category => ({label: category.name, value: category.id})));

  const handleChange = (value: string | null) => {
    if (value === 'CREATE') {
      modalRef.current?.openModal(undefined);
    } else {
      onChange(value ?? undefined);
    }
  }

  return <>
    <Select label={label}
            clearable
            value={categoryId ?? null}
            data={options}
            onChange={handleChange}
            checkIconPosition="right"
            searchable
            allowDeselect={false}
            styles={{
              input: {
                minHeight: '24px',
                maxHeight: '24px',
              }
            }}
    />

    <CategoryModal ref={modalRef} onSuccess={onNewCreated}/>
  </>

}