import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import React, {useEffect, useState} from "react";
import {ApiClient, ApiItem, ApiTransaction, BookingType} from "../../../../utils/http/apiClient";
import {
  daysAgo,
  formatApiDate,
  formatDate,
  startOfMonth,
  startOfWeek,
  startOfYear,
  today
} from "../../../../utils/date/dateUtils";
import {Box, Button, Flex, Grid, LoadingOverlay, Table, Text, Title} from "@mantine/core";
import {IconAbacus, IconColumns3, IconFilter, IconScale} from "@tabler/icons-react";
import {DateInput} from "../../../../common/DateInput";
import {ActionMenu} from "../../../../common/actionButton/ActionMenu";
import {BookingTypeSelect} from "../../../../common/select/BookingTypeSelect";
import {capitalize, formatAmount, formatNumber} from "../../../../utils/formatUtils";
import {StatusBadge} from "../../../../common/StatusBadge";
import {DocumentLink} from "../../../../common/DocumentLink";
import {createIdMap} from "../../../../utils/objectUtils";

export const TransactionList = () => {
  const organisationId = useOrganisationId();
  const [transactions, setTransactions] = useState<ApiTransaction[]>();
  const [itemsById, setItemsById] = useState<Record<string, ApiItem>>({});
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date>(new Date());
  const [bookingTypes, setBookingTypes] = useState<BookingType[]>(['FULL', 'PARTIAL']);

  const setDateRange = (fromDate: Date | null, toDate: Date) => {
    setFromDate(fromDate);
    setToDate(toDate);
  }

  const handleFilter = () => {
    doFilterAndSet(fromDate, toDate, bookingTypes);
  };

  const doFilterAndSet = (fromDate: Date | null, toDate: Date, bookingTypes: BookingType[]) => {
    if (organisationId) {
      setLoading(true);
      Promise.all([
        ApiClient.getTransactions(organisationId, formatApiDate(fromDate), formatApiDate(toDate) ?? '', bookingTypes),
        ApiClient.getItems(organisationId)
      ]).then(([transactions, items]) => {
        setTransactions(transactions.data);
        setItemsById(createIdMap(items.data));
        setFromDate(fromDate);
        setToDate(toDate);
        setBookingTypes(bookingTypes);
      }).finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    handleFilter();
  }, []);

  return <>
    <LoadingOverlay visible={loading}/>
    <Flex direction="row" align="center">
      <IconAbacus size={35} stroke={1.5}/>
      <Title size={24}>
        Transactions
      </Title>
    </Flex>

    <Grid style={{width: '30rem'}} gutter="sm" align="center">
      <Grid.Col span={1.5}>
        <Text size="sm">From: </Text>
      </Grid.Col>

      <Grid.Col span={4}>
        <DateInput value={fromDate}
                   onChange={value => setDateRange(value, toDate)}/>
      </Grid.Col>

      <Grid.Col span={6.5}>
        <ActionMenu label={"Quick filters"}
                    size="xs"
                    options={[
                      {label: 'Today', action: () => doFilterAndSet(today(), today(), bookingTypes)},
                      'DIVIDER',
                      {label: 'Week to Date', action: () => doFilterAndSet(startOfWeek(), today(), bookingTypes)},
                      {label: 'Month to Date', action: () => doFilterAndSet(startOfMonth(), today(), bookingTypes)},
                      {label: 'Year to Date', action: () => doFilterAndSet(startOfYear(), today(), bookingTypes)},
                      'DIVIDER',
                      {label: 'Last 7 days', action: () => doFilterAndSet(daysAgo(6), today(), bookingTypes)},
                      {label: 'Last 30 days', action: () => doFilterAndSet(daysAgo(29), today(), bookingTypes)},
                      {label: 'Last 90 days', action: () => doFilterAndSet(daysAgo(89), today(), bookingTypes)},
                    ]}/>
      </Grid.Col>

      <Grid.Col span={1.5}>
        <Text size="sm">To: </Text>
      </Grid.Col>

      <Grid.Col span={4}>
        <DateInput value={toDate}
                   onChange={value => setDateRange(fromDate, value ?? new Date())}/>
      </Grid.Col>

      <Grid.Col span={6.5}>
        <Button size="xs" leftSection={<IconFilter size="1rem" stroke={1.5}/>} onClick={handleFilter}>Filter</Button>
      </Grid.Col>

      <Grid.Col span={2.5}>
        <Text size="sm">Booking Type: </Text>
      </Grid.Col>

      <Grid.Col span={6.5}>
        <BookingTypeSelect values={bookingTypes} onChange={bookingTypes => setBookingTypes(bookingTypes)}/>
      </Grid.Col>

    </Grid>

    <Box style={{paddingTop: '15px'}}>
      <LoadingOverlay visible={loading}/>
      <Table verticalSpacing={1}
             withTableBorder
             withColumnBorders
             highlightOnHover={true}
             className={'datatable'}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{width: "1%", textAlign: "right"}} rowSpan={3}>
              <IconColumns3 style={{marginTop: '5px'}}/>
            </Table.Th>
            <Table.Th style={{textAlign: "center"}}>Booking Type</Table.Th>
            <Table.Th style={{textAlign: "center"}}>Booking Date</Table.Th>
            <Table.Th style={{textAlign: "center"}}>Type</Table.Th>
            <Table.Th style={{textAlign: "center"}}>Document</Table.Th>
            <Table.Th style={{textAlign: "center"}}>Item</Table.Th>
            <Table.Th style={{textAlign: "center"}}>Amount</Table.Th>
            <Table.Th style={{textAlign: "center"}}>Quantity</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {transactions?.map((transaction, idx) => (
            <Table.Tr key={idx}>
              <Table.Td style={{textAlign: "center"}}>
                {idx + 1}
              </Table.Td>

              <Table.Td style={{textAlign: "center"}}>
                <StatusBadge status={transaction.bookingType}/>
              </Table.Td>
              <Table.Td style={{textAlign: "center"}}>
                {formatDate(transaction.bookingDate)}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}}>
                {transaction.type}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}}>
                <DocumentLink type={transaction.type} id={transaction.documentId}/>
              </Table.Td>
              <Table.Td style={{textAlign: "center"}}>
                {itemsById[transaction.itemId]?.name}
              </Table.Td>
              <Table.Td style={{textAlign: "right"}} className={transaction?.amount < 0 ? 'negative' : ''}>
                {formatNumber(transaction.amount)}
              </Table.Td>
              <Table.Td style={{textAlign: "right"}} className={transaction?.quantity < 0 ? 'negative' : ''}>
                {formatNumber(transaction.quantity)}
              </Table.Td>
            </Table.Tr>

          ))}
        </Table.Tbody>
      </Table>
    </Box>
  </>
}