export enum Environment {
  DEV = "DEV",
  STAGING = "STAGING",
  PROD = "PROD"
}

export interface EnvironmentConfig {
  apiUrl: string
}

const ENV_CONFIGS: Record<Environment, EnvironmentConfig> = {
  [Environment.DEV]: {
    apiUrl: 'http://localhost:8080'
  },
  [Environment.STAGING]: {
    apiUrl: 'https://api.munita.dev'
  },
  [Environment.PROD]: {
    apiUrl: 'https://backend.munita.biz'
  },
}


export function getEnvironmentConfig() {
  const hostname = window.location.hostname;

  if (hostname.startsWith('localhost')) {
    return ENV_CONFIGS[Environment.DEV];
  }

  if (hostname.startsWith('munita-frontend-hm3jtfnfyq-ew.a.run.app')
    || hostname.startsWith('www.munita.dev')) {
    return ENV_CONFIGS[Environment.STAGING];
  }

  if (hostname.startsWith('www.munita.biz')) {
    return ENV_CONFIGS[Environment.PROD];
  }

  return ENV_CONFIGS[Environment.DEV];
}