import {ApiClient} from "../utils/http/apiClient";
import {clearAllTokens, storeAccessToken} from "../utils/token/tokenCache";
import {useContext} from "react";
import {AuthContext} from "../context/AuthProvider";
import {JwtTokenUtils} from "../utils/token/jwtToken";

export const useAuth = () => {
  const {auth, setAuth} = useContext(AuthContext);

  const doLogin = async (email: string, password: string) => {
    console.log("doLogin");
    try {
      const tokenResponse = await ApiClient.doLogin(email, password);
      storeAccessToken(tokenResponse.data.accessToken);
      setAuth({accessToken: tokenResponse.data.accessToken});
    } catch (_) {
      clearAllTokens();
    }
  }

  const doRefresh = async () => {
    try {
      console.log("doRefresh");
      const tokenResponse = await ApiClient.doRefresh();
      storeAccessToken(tokenResponse.data.accessToken);
      setAuth({accessToken: tokenResponse.data.accessToken});
    } catch (_) {
      clearAllTokens();
    }
  }

  const doLogout = async () => {
    console.log("doLogout");
    clearAllTokens();
    setAuth({accessToken: undefined});
    return await ApiClient.doLogout();
  }

  const setupRefreshTask = () => {
    if (auth.accessToken) {
      const epochSecondsNow = Date.now() / 1000;
      const expiresAt = JwtTokenUtils.decodeJwtToken(auth.accessToken).exp;
      const expiresIn = expiresAt - epochSecondsNow;

      console.log(`Will refresh in ${Math.round(expiresIn - 30)} seconds`);
      setTimeout(async () => {
        console.log("Running scheduled refresh");
        await doRefresh();
      }, (expiresIn - 30) * 1000);
    }
  };

  return {auth, setAuth, doLogin, doRefresh, doLogout, setupRefreshTask};
}