import {ApiItemCategory} from "../../../../utils/http/apiClient";
import React, {useRef} from "react";
import {Button, Space, Stack, Table} from "@mantine/core";
import {IconCheck, IconColumns3, IconPlus} from "@tabler/icons-react";
import {CategoryModal} from "./CategoryModal";

interface Props {
  categories: ApiItemCategory[]
  fetchEntities: () => void
}

export const CategoriesTab = ({categories, fetchEntities}: Props) => {
  const categoryModalRef = useRef<any>();

  return <>
    <Space h={10}/>
    <Stack dir="column" style={{width: '1200px'}} align="flex-start">
      <Button size="xs" rightSection={<IconPlus/>} onClick={() => categoryModalRef.current?.openModal(undefined)}>
        New
      </Button>

      <Table verticalSpacing={1}
             withTableBorder
             withColumnBorders
             highlightOnHover={true}
             className={'datatable'}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{width: "1%", textAlign: "right"}}>
              <IconColumns3 style={{marginTop: '5px'}}/>
            </Table.Th>
            <Table.Th>Name</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "right"}}>Active</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {categories.map((category, idx) => (
            <Table.Tr key={idx} className={category.active ? '' : 'inactive'}>
              <Table.Td style={{textAlign: "center"}}
                        onClick={() => categoryModalRef.current?.openModal(category)}>
                {idx + 1}
              </Table.Td>
              <Table.Td onClick={() => categoryModalRef.current?.openModal(category)}>
                {category.name}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}}
                        onClick={() => categoryModalRef.current?.openModal(category)}>
                {category.active && <IconCheck size={16} stroke={1.5}/>}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Stack>
    <CategoryModal ref={categoryModalRef} onSuccess={fetchEntities}/>
  </>
}