import {DateInput as MantineDateInput, DateValue} from "@mantine/dates";
import React, {memo, useState} from "react";
import dayjs from "dayjs";
import {dateParser, formatApiDate, toUTCDate} from "../utils/date/dateUtils";

export interface Props {
  value: string | null
  onChange: (value: string | null) => void
  onBlur?: () => void
}

export const DateInputV2 = memo(({value, onChange, onBlur}: Props) => {
  return <>
    <MantineDateInput valueFormat="DD.MM.YYYY"
                      value={value ? dayjs(value).toDate() : null}
                      onChange={(date) => onChange(formatApiDate(toUTCDate(date)))}
                      onBlur={() => onBlur?.()}
                      placeholder={dayjs().format('DD.MM.YYYY')}
                      dateParser={dateParser}
                      clearable={true}
                      highlightToday={true}
                      styles={{
                        input: {
                          minHeight: '24px',
                          maxHeight: '24px',
                        }
                      }}
    />
  </>

});