import {TextInput as MantineTextInput} from "@mantine/core";
import {memo, useEffect, useState} from "react";

export interface Props {
  value: string | null
  onChange: (value: string | null) => void;
  label?: string
  placeholder?: string
  disabled?: boolean
  tabIndex?: number
}

export const TextInput = memo(({
                                 value,
                                 onChange,
                                 placeholder,
                                 disabled,
                                 tabIndex
                               }: Props) => {

  const [internalValue, setInternalValue] = useState('');

  useEffect(() => {
    setInternalValue(value ?? '');
  }, [value]);

  return <MantineTextInput value={internalValue}
                           onChange={e => setInternalValue(e.target.value)}
                           onBlur={() => onChange(internalValue)}
                           placeholder={placeholder}
                           disabled={disabled}
                           tabIndex={tabIndex}
                           styles={{
                             input: {
                               minHeight: '24px',
                               maxHeight: '24px',
                               width: '100%'
                             }
                           }}
  />
});