import {Button, Flex, Modal, Switch, TextInput} from "@mantine/core";
import React, {FormEvent, useCallback, useImperativeHandle, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {notifications} from "@mantine/notifications";
import {ApiClient, ApiTax, UpsertTaxRequest} from "../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import {IconCheck, IconCircleX, IconExclamationCircle} from "@tabler/icons-react";
import {PercentageInput} from "../../../../common/PercentageInput";
import {ModalTitle} from "../../../../common/ModalTitle";

interface Props {
  onSuccess?: (apiTax: ApiTax) => void
  onClose?: () => void
}

interface Tax {
  id?: string
  name: string
  rate: number | null
  active: boolean
}

const mapApiTax = (apiTax?: ApiTax) => {
  return {
    id: apiTax?.id,
    name: apiTax ? apiTax.name : '',
    rate: apiTax ? apiTax.rate * 100 : '',
    active: apiTax ? apiTax.active : true,
  } as Tax;
}

export const TaxModal = React.forwardRef(({onSuccess, onClose}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const [tax, setTax] = useState<Tax>(mapApiTax(undefined));

  const openModal = useCallback((apiTax?: ApiTax) => {
    setTax(mapApiTax(apiTax));
    open();
  }, [open]);

  const closeModal = useCallback(() => {
    setTax(mapApiTax(undefined))
    onClose?.();
    close();
  }, [setTax, close]);

  useImperativeHandle(ref, () => ({openModal}));

  const handleSave = (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    setLoading(true);
    const request = {name: tax.name, rate: Number(tax.rate) / 100, active: tax.active} as UpsertTaxRequest;
    (tax.id
        ? ApiClient.updateTax(organisationId ?? '', tax.id, request)
        : ApiClient.createTax(organisationId ?? '', request)
    )
      .then((resp) => {
        onSuccess?.(resp.data);
      })
      .then(() => closeModal())
      .catch(err => notifications.show({
        message: "Failed to save tax: " + err.message, color: "red", icon: <IconExclamationCircle/>
      }))
      .finally(() => setLoading(false));
  }

  return <>
    <Modal opened={opened}
           id="tax_modal"
           onClose={closeModal}
           title={<ModalTitle name="Tax" id={tax?.id}/>}
           closeOnClickOutside={false}
           returnFocus={true}
           transitionProps={{duration: 100}}
           overlayProps={{opacity: 0.5}}
    >
      <Flex align="center" direction="column" gap="md">
        <form style={{width: "100%"}} onSubmit={handleSave}>
          <TextInput placeholder="Some tax name"
                     label="Name"
                     data-autofocus
                     value={tax.name}
                     disabled={loading}
                     onChange={e => setTax({...tax, name: e.target.value})}
          />
          <PercentageInput label="Rate"
                           placeholder="Between 0% and 100%"
                           value={tax.rate}
                           disabled={loading}
                           onChange={e => setTax({...tax, rate: e})}/>

          <Flex direction="row" justify="right" style={{width: "100%", paddingTop: '30px'}}>
            <Switch size={"sm"}
                    labelPosition="left"
                    label="Active"
                    style={{paddingTop: '5px'}}
                    checked={tax.active}
                    onChange={e => setTax({...tax, active: e.target.checked})}
            />
          </Flex>

          <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
            <Button type="submit" rightSection={<IconCheck/>} loading={loading}>
              Save
            </Button>
            <Button variant="outline" rightSection={<IconCircleX/>} onClick={closeModal} disabled={loading}>
              Cancel
            </Button>
          </Flex>
        </form>
      </Flex>
    </Modal>
  </>;
});