import {Button, Fieldset, Flex, PasswordInput, TextInput, Title} from "@mantine/core";
import React, {FormEvent, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {AxiosError} from "axios";

export const Login = () => {
  const {doLogin} = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    doLogin(username, password)
      .then(() => {
        setUsername('');
        setPassword('');
        navigate('/app');
      })
      .catch(err => {
        if (err instanceof AxiosError && !err.response) {
          setError('No Server Response');
        } else if (err instanceof AxiosError && err.response?.status === 400) {
          setError('Missing Username or Password');
        } else if (err instanceof AxiosError && err.response?.status === 401) {
          setError('Unauthorized');
        } else {
          setError('Login Failed');
          console.log(err);
        }
      }).finally(() => setLoading(false));
  };

  return <Flex align="center" direction="column" gap="md">
    <form onSubmit={handleLogin}>
      <Fieldset disabled={isLoading}>
        <Flex align="center" direction="column" gap="md">
          <Title order={1} style={{color: 'var(--mantine-color-violet-5)'}}>
            Login
          </Title>

          <TextInput placeholder="example@company.com" label="Email" withAsterisk style={{width: '300px'}}
                     value={username}
                     onChange={(e) => setUsername(e.target.value)}/>
          <PasswordInput placeholder="Password" label="Password" withAsterisk style={{width: '300px'}}
                         value={password}
                         onChange={(e) => setPassword(e.target.value)}/>

          <Button type="submit">Login</Button>
        </Flex>
      </Fieldset>
    </form>
  </Flex>
}