import React, {useEffect, useState} from "react";
import {Button, Fieldset, Flex, Loader, Title} from "@mantine/core";
import {ApiAddress, ApiClient, ApiOrganisationSetting} from "../../../utils/http/apiClient";
import {useParams} from "react-router-dom";
import {notifications} from "@mantine/notifications";
import {IconCheck, IconExclamationCircle, IconSettings} from "@tabler/icons-react";
import {notifyError} from "../../../utils/notificationUtils";
import {TextInput} from "../../../common/TextInput";
import {initIfEmpty} from "../../../utils/objectUtils";

export const OrgSettings = () => {
  const params = useParams();
  const organisationId = params['organisationId'];
  const [orgName, setOrgName] = useState<string>('');
  const [settings, setSettings] = useState<ApiOrganisationSetting>({} as ApiOrganisationSetting);
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchOrganisation = () => {
    if (organisationId) {
      setLoading(true);
      ApiClient.getOrganisation(organisationId)
        .then(resp => {
          setOrgName(resp.data.name);
          setSettings(resp.data.settings);
          setTouched(false);
        })
        .catch(notifyError)
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchOrganisation();
  }, [organisationId]);

  const updateSettings = (settings: ApiOrganisationSetting) => {
    setSettings(settings);
    setTouched(true);
  }

  const handleSave = () => {
    if (organisationId && settings) {
      setLoading(true);
      ApiClient.updateOrganisationSettings(organisationId, settings)
        .then(() => {
          setTouched(false);
          notifications.show({message: "Saved changes", color: "green", icon: <IconCheck/>});
        })
        .catch(err => {
          notifications.show({
            message: "Failed to save changes: " + err.message,
            color: "red",
            icon: <IconExclamationCircle/>
          });
        })
        .finally(() => setLoading(false));
    }
  }

  const handleCancel = () => {
    fetchOrganisation();
  }

  return <>
    <form>
      <Flex direction="column" style={{width: "480px"}} gap="md" rowGap='xs'>
        <Flex direction="row" align="center">
          <IconSettings size={35} stroke={1.5}/>
          <Title size={24}>
            Settings: {orgName}
          </Title>

        </Flex>
        <Fieldset legend="Financial information" disabled={loading}>
          <TextInput placeholder="e.g. OIB"
                     label="Registration Number"
                     value={settings?.registrationNumber}
                     onChange={(e) => updateSettings({...settings, registrationNumber: e})}
          />
          <TextInput placeholder="e.g. EUR"
                     label="Base currency"
                     value={settings?.baseCurrency}
                     onChange={(e) => updateSettings({...settings, baseCurrency: e})}
          />
        </Fieldset>
        <Fieldset legend="Contact information" disabled={loading}>
          <TextInput placeholder="info@organisation.com"
                     label="Email"
                     value={settings?.email}
                     onChange={(e) => updateSettings({...settings, email: e})}
          />
          <TextInput placeholder="+385 01 234 4567"
                     label="Phone number"
                     value={settings?.phoneNumber}
                     onChange={(e) => updateSettings({...settings, phoneNumber: e})}
          />
        </Fieldset>
        <Fieldset legend="Address" disabled={loading}>
          <TextInput placeholder="Address First Line"
                     label="Address Line 1"
                     value={settings?.address?.line1 ?? null}
                     onChange={(e) => updateSettings({
                       ...settings,
                       address: {
                         ...initIfEmpty(settings.address),
                         line1: e
                       }
                     })}
          />
          <TextInput placeholder="Address Second Line"
                     label="Address Line 2"
                     value={settings?.address?.line2 ?? null}
                     onChange={(e) => updateSettings({
                       ...settings,
                       address: {...initIfEmpty(settings.address), line2: e}
                     })}
          />
          <TextInput placeholder="10000"
                     label="Postal code"
                     value={settings?.address?.postalCode ?? null}
                     onChange={(e) => updateSettings({
                       ...settings,
                       address: {...initIfEmpty(settings.address), postalCode: e}
                     })}
          />
          <TextInput placeholder="Zagreb"
                     label="City"
                     value={settings?.address?.city ?? null}
                     onChange={(e) => updateSettings({
                       ...settings,
                       address: {...initIfEmpty(settings.address), city: e}
                     })}
          />
          <TextInput placeholder="Croatia"
                     label="Country"
                     value={settings?.address?.country ?? null}
                     onChange={(value) => updateSettings({
                       ...settings,
                       address: {
                         ...(settings.address ?? {} as ApiAddress),
                         country: value
                       }
                     })}
          />
        </Fieldset>
        <Flex direction="row" justify="space-around">
          <Button disabled={!touched || loading} variant="filled" onClick={handleSave}
                  leftSection={loading && <Loader size="xs"/>}>
            Save
          </Button>
          <Button disabled={!touched || loading} variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </form>

  </>;
}