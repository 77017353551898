import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import React, {useCallback, useEffect, useState} from "react";
import {ApiClient, ApiItem, ApiItemCategory, ApiUnit} from "../../../../utils/http/apiClient";
import {IconCategory, IconCoffee, IconRuler2} from "@tabler/icons-react";
import {Box, LoadingOverlay, Tabs} from "@mantine/core";
import {createIdMap} from "../../../../utils/objectUtils";
import {ItemsTab} from "./ItemsTab";
import {notifyError} from "../../../../utils/notificationUtils";
import {CategoriesTab} from "./CategoriesTab";
import {UnitsTab} from "./UnitsTab";

function sortItems(items: ApiItem[], categories: ApiItemCategory[]) {
  const categoriesById = createIdMap(categories);

  return items.sort((a, b) => {
    const categoryA = categoriesById[a.categoryId]?.name;
    const categoryB = categoriesById[b.categoryId]?.name;

    return categoryA === categoryB
      ? a.name.localeCompare(b.name)
      : categoryA.localeCompare(categoryB);
  })
}

export const ItemList = () => {
  const organisationId = useOrganisationId();
  const [items, setItems] = useState<ApiItem[]>([]);
  const [categories, setCategories] = useState<ApiItemCategory[]>([]);
  const [units, setUnits] = useState<ApiUnit[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchEntities = useCallback(() => {
    if (organisationId) {
      setLoading(true);
      Promise.all([
        ApiClient.getItems(organisationId),
        ApiClient.getItemCategories(organisationId),
        ApiClient.getUnits(organisationId)
      ]).then(([items, categories, units]) => {
        sortItems(items.data, categories.data);
        setItems(items.data);
        setCategories(categories.data);
        setUnits(units.data);
      }).catch(notifyError)
        .finally(() => setLoading(false));
    }
  }, [organisationId]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  return <>
    <Box style={{paddingTop: '15px'}}>
      <LoadingOverlay visible={loading}/>

      <Tabs variant="outline" defaultValue="items">
        <Tabs.List>
          <Tabs.Tab value="items" leftSection={<IconCoffee stroke={1.5}/>}>
            Items
          </Tabs.Tab>
          <Tabs.Tab value="categories" leftSection={<IconCategory stroke={1.5}/>}>
            Categories
          </Tabs.Tab>

          <Tabs.Tab value="units" leftSection={<IconRuler2 stroke={1.5}/>}>
            Units
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="items">
          <ItemsTab items={items} categories={categories} units={units} fetchEntities={fetchEntities}/>
        </Tabs.Panel>

        <Tabs.Panel value="categories">
          <CategoriesTab categories={categories} fetchEntities={fetchEntities}/>
        </Tabs.Panel>

        <Tabs.Panel value="units">
          <UnitsTab units={units} fetchEntities={fetchEntities}/>
        </Tabs.Panel>
      </Tabs>
    </Box>
  </>
}
