import {Anchor} from "@mantine/core";
import React from "react";

interface Props {
  name: string | null
  onClick?: () => void
}

export const ObjectLink = ({name, onClick}: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    onClick?.();
  }

  return <Anchor size="sm" onClick={e => handleClick(e)}>
    {name}
  </Anchor>
}