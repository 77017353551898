import {ApiBox, ApiTextBox} from "../../../../../utils/http/apiClient";

export interface Point {
  x: number
  y: number
}

export interface Box {
  text: string
  l: number
  t: number
  w: number
  h: number
  id: number
}

export interface BoxGroup {
  boxes: Box[]
  text: string
  l: number
  t: number
  w: number
  h: number
  id: number
}

export function mapToBox(id: number, apiBox: ApiTextBox) {
  return {
    text: apiBox.text.trim(),
    l: apiBox.box.l,
    t: apiBox.box.t,
    w: apiBox.box.w,
    h: apiBox.box.h,
    id: id
  } as Box;
}

export function mapToBoxGroup(box: Box) {
  return {
    text: box.text,
    l: box.l,
    t: box.t,
    w: box.w,
    h: box.h,
    boxes: [box]
  } as BoxGroup;
}

export function boxesEqual(bg1: BoxGroup | Box, bg2: BoxGroup | Box) {
  return bg1.text == bg2.text
    && bg1.l == bg2.l
    && bg1.t == bg2.t
    && bg1.w == bg2.w
    && bg1.h == bg2.h;
}

export function isBoxBeingSelected(box: Box | BoxGroup, mousePosition: Point | null, selectionBox: Box | null) {
  return isPointInsideBox(mousePosition, box) || (selectionBox !== null && boxesIntersect(selectionBox, box));
}

export function boxGroupContains(boxGroup: BoxGroup, box: Box) {
  return boxGroup.boxes.filter(b => boxesEqual(b, box)).length > 0;
}

export function removeBox(boxGroup: BoxGroup, box: Box) {
  const boxes = boxGroup.boxes.filter(b => !boxesEqual(b, box));
  return createBoxGroup(boxes);
}

export function mergeBoxGroups(boxGroups: BoxGroup[]) {
  const boxes = boxGroups.flatMap(bg => bg.boxes);
  return createBoxGroup(boxes);
}

export function createBoxGroup(boxes: Box[]) {
  let text = "";
  let leftTop = {
    x: boxes.length > 0 ? boxes[0].l : 0,
    y: boxes.length > 0 ? boxes[0].t : 0,
  } as Point
  let bottomRight = {
    x: boxes.length > 0 ? boxes[0].l + boxes[0].w : 0,
    y: boxes.length > 0 ? boxes[0].t + boxes[0].h : 0
  } as Point

  for (let i = 0; i < boxes.length; i++) {
    text += boxes[i].text + " ";
    leftTop.x = Math.min(leftTop.x, boxes[i].l);
    leftTop.y = Math.min(leftTop.y, boxes[i].t);
    bottomRight.x = Math.max(bottomRight.x, boxes[i].l + boxes[i].w);
    bottomRight.y = Math.max(bottomRight.y, boxes[i].t + boxes[i].h);
  }
  return {
    text: text.trim(),
    boxes,
    l: leftTop.x,
    t: leftTop.y,
    w: bottomRight.x - leftTop.x,
    h: bottomRight.y - leftTop.y,
    id: boxes[0].id,
  } as BoxGroup
}

export function boxesInLine(box1: Box, box2: Box) {
  if (box1.t < box2.t) {
    return box2.t < box1.t + box1.h;
  } else {
    return box1.t < box2.t + box2.h;
  }
}

export function drawBox(context: CanvasRenderingContext2D,
                        box: Box,
                        strokeStyle: string,
                        lineWidth: number,
                        zoom: number) {
  context.strokeStyle = strokeStyle;
  context.lineWidth = lineWidth;
  context.strokeRect(
    box.l * zoom,
    box.t * zoom,
    box.w * zoom,
    box.h * zoom);
}

export function drawBoxes(context: CanvasRenderingContext2D,
                          boxes: Box[],
                          strokeStyle: string,
                          lineWidth: number,
                          leftOffset: number,
                          topOffset: number,
                          zoom: number) {
  context.strokeStyle = strokeStyle;
  context.lineWidth = lineWidth;
  boxes.forEach(box => {
    const l = box.l * zoom + leftOffset;
    const t = box.t * zoom + topOffset;
    const w = box.w * zoom;
    const h = box.h * zoom;
    context.strokeRect(l, t, w, h);
  });
}


export function boxesIntersect(box1: Box, box2: Box) {
  return rectsIntersect(
    box1.l, box1.t, box1.l + box1.w, box1.t + box1.h,
    box2.l, box2.t, box2.l + box2.w, box2.t + box2.h);
}

export function rectsIntersect(l1: number, t1: number, r1: number, b1: number,
                               l2: number, t2: number, r2: number, b2: number) {
  return Math.max(l1, l2) < Math.min(r1, r2) && Math.max(t1, t2) < Math.min(b1, b2);
}

export function isPointInsideBox(p: Point | null | undefined, b: ApiBox) {
  if (!p) {
    return false;
  }
  return b.l <= p.x && p.x <= b.l + b.w && b.t <= p.y && p.y <= b.t + b.h;
}

export function groupIntoLines(boxGroups: BoxGroup[]) {
  const boxes = boxGroups.flatMap(b => b.boxes).sort((a,b) => a.id - b.id);
  let firstBoxIdx = 0;
  let newBoxGroups = [];
  while (firstBoxIdx < boxes.length) {
    let currBox = firstBoxIdx;
    let boxGroup = {text: '', boxes: [], l: 0, t: 0, w: 0, h: 0, id: boxes[firstBoxIdx].id} as BoxGroup
    let leftTop = {
      x: boxes[firstBoxIdx].l,
      y: boxes[firstBoxIdx].t
    } as Point
    let bottomRight = {
      x: boxes[firstBoxIdx].l + boxes[firstBoxIdx].w,
      y: boxes[firstBoxIdx].t + boxes[firstBoxIdx].h
    } as Point

    while (currBox < boxes.length && boxesInLine(boxes[firstBoxIdx], boxes[currBox])) {
      boxGroup.boxes.push(boxes[currBox]);
      boxGroup.text += boxes[currBox].text + " ";
      leftTop.x = Math.min(leftTop.x, boxes[currBox].l);
      leftTop.y = Math.min(leftTop.y, boxes[currBox].t);
      bottomRight.x = Math.max(leftTop.x, boxes[currBox].l + boxes[currBox].w);
      bottomRight.y = Math.max(leftTop.y, boxes[currBox].t + boxes[currBox].h);
      currBox++;
    }
    boxGroup.l = leftTop.x;
    boxGroup.t = leftTop.y;
    boxGroup.w = bottomRight.x - leftTop.x;
    boxGroup.h = bottomRight.y - leftTop.y;
    firstBoxIdx = currBox;
    newBoxGroups.push(boxGroup);
  }
  return newBoxGroups.sort((a,b) => a.t - b.t);
}