import {Select} from "@mantine/core";
import React, {useRef} from "react";
import {ApiUnit} from "../../utils/http/apiClient";
import {UnitModal} from "../../pages/private/organisation/items/UnitModal";

export interface Props {
  unitId?: string
  items: ApiUnit[],
  onChange: (value?: string) => void
  onNewCreated: (newUnit: ApiUnit) => void
  label?: string
  onBlur?: () => void
}

export const UnitSelect = ({
                             unitId,
                             items,
                             onChange,
                             onNewCreated,
                             label,
                             onBlur
                           }: Props) => {
  const modalRef = useRef<any>();

  const options = [{label: "+ Create new", value: "CREATE"}]
  options.push(
    ...items.filter(unit => unit.active || unitId === unit.id)
      .map(unit => ({label: unit.shortName, value: unit.id})));

  const handleChange = (value: string | null) => {
    if (value === 'CREATE') {
      modalRef.current?.openModal(undefined);
    } else {
      onChange(value ?? undefined);
    }
  }

  return <>
    <Select label={label}
            clearable
            value={unitId ?? null}
            data={options}
            onChange={handleChange}
            checkIconPosition="right"
            searchable
            allowDeselect={false}
            styles={{
              input: {
                minHeight: '24px',
                maxHeight: '24px',
              }
            }}
    />

    <UnitModal ref={modalRef} onSuccess={onNewCreated}/>
  </>

}