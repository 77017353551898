import {NumberInput as MantineNumberInput} from "@mantine/core";
import React, {KeyboardEvent as ReactKeyboardEvent, memo, useEffect, useRef, useState} from "react";
import {ChangeCause, mapChangeCause} from "./DataSheet";

interface Props {
  value: number | null
  onChange: (value: number | null, cause: ChangeCause) => void
  nonNegative?: boolean
  defaultValue?: number
}

export const DataSheetAmountInput = ({
                                       value,
                                       onChange,
                                       nonNegative,
                                       defaultValue,
                                     }: Props) => {
  const [internalValue, setInternalValue] = useState<number | string>(value ?? '');

  useEffect(() => {
    setInternalValue(value ?? '');
  }, [value]);


  const handleSubmit = (cause: ChangeCause) => {
    if (typeof internalValue === 'number') {
      onChange(internalValue, cause);
    } else {
      onChange(null, cause);
    }
  }

  const handleChange = (newValue: string | number) => {
    setInternalValue(newValue);
  }

  const handleKeyDown = (event: ReactKeyboardEvent<HTMLInputElement>) => {
    const cause = mapChangeCause(event.key);
    if (cause !== 'Unknown') {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(event.key as ChangeCause);
    }
  }

  return <MantineNumberInput min={nonNegative ? 0 : -1_000_000_000}
                             max={1_000_000_000}
                             thousandSeparator=","
                             hideControls
                             autoFocus
                             value={internalValue}
                             defaultValue={defaultValue}
                             decimalScale={2}
                             onClick={e => e.stopPropagation()}
                             onMouseDown={e => e.stopPropagation()}
                             onKeyDown={handleKeyDown}
                             onChange={handleChange}
  />
}