import React from "react";
import {Flex, Space, Text} from "@mantine/core";

interface Props {
  name: string
  id?: string
}

export const ModalTitle = ({name, id}: Props) => {
  return <>
    <Flex direction="row" align="center">
      <Text size="md" fw={700}>{name}</Text>
      <Space w={10} />
      <Text size="xs" c="gray.8">[ID: {id}]</Text>
    </Flex>
  </>;
}