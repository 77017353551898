import {useDisclosure} from "@mantine/hooks";
import React, {FormEvent, useCallback, useImperativeHandle, useState} from "react";
import {Button, Flex, Grid, Modal, Space, Text} from "@mantine/core";
import {IconCheck, IconCircleX} from "@tabler/icons-react";
import {TextInput} from "../TextInput";

interface Props {
  onSuccess?: (searchValue: string, replaceValue: string) => void
  onClose?: () => void
}

export const DataSheetSearchAndReplaceModal = React.forwardRef(({onSuccess, onClose}: Props, ref) => {

  const [opened, {open, close}] = useDisclosure(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [replaceValue, setReplaceValue] = useState<string | null>(null);

  const openModal = useCallback(() => {
    open();
    setSearchValue('');
    setReplaceValue('');
  }, [open]);

  const closeModal = useCallback(() => {
    close();
    onClose?.();
  }, [close]);

  useImperativeHandle(ref, () => ({openModal}));

  const handleSave = (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (!searchValue || !replaceValue) {
      return;
    }

    onSuccess?.(searchValue, replaceValue);
    onClose?.();
    close();
  }

  return <>
    <Modal opened={opened}
           onClose={closeModal}
           title="Search & Replace"
           closeOnClickOutside={false}
           transitionProps={{duration: 100}}
           size="auto"
    >
      <Flex align="center" direction="column" gap="md">
        <form style={{width: "100%"}} onSubmit={handleSave}>

          <Grid style={{width: '23rem'}} gutter="sm">

            <Grid.Col span={4}>
              <Text size="sm">Search value: </Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <TextInput value={searchValue} onChange={setSearchValue} />
            </Grid.Col>

            <Grid.Col span={4}>
              <Text size="sm">Replace value: </Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <TextInput value={replaceValue} onChange={setReplaceValue}/>
            </Grid.Col>

          </Grid>

          <Space h="1rem"/>

          <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
            <Button type="submit" rightSection={<IconCheck/>}>
              Save
            </Button>
            <Button variant="outline" rightSection={<IconCircleX/>} onClick={closeModal}>
              Cancel
            </Button>
          </Flex>
        </form>
      </Flex>
    </Modal>
  </>;
});