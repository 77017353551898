import {MultiSelect} from "@mantine/core";
import {BookingType} from "../../utils/http/apiClient";

interface Props {
  values: BookingType[]
  onChange: (values: BookingType[]) => void
}

export const BookingTypeSelect = ({values, onChange}: Props) => {
  const options = ['FULL', 'PARTIAL'] as BookingType[];

  return <MultiSelect data={options}
                      defaultValue={options}
                      value={values}
                      onChange={newValues => newValues.length != 0 && onChange(newValues as BookingType[])}
                      size="xs"/>
}