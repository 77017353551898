import {Select} from "@mantine/core";
import React, {memo, useRef} from "react";
import {ApiRegister} from "../../utils/http/apiClient";

export interface Props {
  registerId?: string
  registers: ApiRegister[],
  onChange: (value?: string) => void
  onNewCreated: (newRegister: ApiRegister) => void
  inputLabel?: string
}

export const RegisterSelect = memo(({
                                     registerId,
                                     registers,
                                     onChange,
                                     onNewCreated,
                                     inputLabel
                                   }: Props) => {
  const modalRef = useRef<any>();

  const options = [];
  options.push({label: "+ Create new", value: "CREATE"});
  options.push(
    ...registers.map(contact => ({label: contact.name ?? 'MISSING', value: contact.id})));

  const handleChange = (value: string | null) => {
    if (value === 'CREATE') {
      modalRef.current?.openModal(undefined);
    } else {
      onChange(value ?? undefined);
    }
  }

  return <>
    <Select clearable
            value={registerId ?? null}
            data={options}
            onChange={handleChange}
            checkIconPosition="right"
            searchable
            hiddenInputProps={{
              autoFocus: true
            }}
            allowDeselect={false}
            placeholder={"Select " + (inputLabel?.toLowerCase() ?? "register")}
            styles={{
              input: {
                minHeight: "24px",
                maxHeight: "24px",
              }
            }}
    />

    {/*<ContactModal ref={modalRef} onSuccess={onNewCreated}/>*/}
  </>

});