import {Anchor} from "@mantine/core";
import {DocumentType} from "../utils/http/apiClient";
import React from "react";

type ObjectType = 'INCOMING_INVOICE' | 'REGISTER_REPORT' | 'WORK_ORDER'

function shortDocumentName(type: ObjectType | DocumentType) {
  if (type === 'INCOMING_INVOICE') {
    return 'II'
  } else if (type === 'REGISTER_REPORT') {
    return 'RR'
  } else if (type === 'WORK_ORDER') {
    return 'WO'
  } else {
    return 'UNKNOWN';
  }
}

interface Props {
  type: ObjectType | DocumentType
  id: string | null,
  onClick?: () => void
}

export const DocumentLink = ({type, id, onClick}: Props) => {
  if (id === null) {
    return <></>
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    onClick?.();
  }

  return <Anchor size="sm" onClick={e => handleClick(e)}
                 style={{fontFamily: 'Monospace', fontSize: '12px'}}>
    {`${shortDocumentName(type)}-${id.substring(0, 6).toUpperCase()}`}
  </Anchor>
}