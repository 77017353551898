import {createContext, Dispatch, PropsWithChildren, SetStateAction, useState} from "react";
import {getAccessToken} from "../utils/token/tokenCache";

export interface Auth {
  accessToken?: string
}

export interface AuthContextType {
  auth: Auth
  setAuth: Dispatch<SetStateAction<Auth>>
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({children}: PropsWithChildren) => {
  const [auth, setAuth] = useState<Auth>({accessToken: getAccessToken() ?? undefined});

  return <AuthContext.Provider value={{auth, setAuth}}>
    {children}
  </AuthContext.Provider>

};