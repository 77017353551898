import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ApiClient, ApiTax} from "../../../../utils/http/apiClient";
import {IconCheck, IconColumns3, IconPlus, IconReceiptTax} from "@tabler/icons-react";
import {Box, Button, Flex, LoadingOverlay, Space, Table, Title} from "@mantine/core";
import {TaxModal} from "./TaxModal";
import {formatDateTime} from "../../../../utils/date/dateUtils";
import {notifyError} from "../../../../utils/notificationUtils";

export const TaxList = () => {
  const organisationId = useOrganisationId();
  const [taxes, setTaxes] = useState<ApiTax[]>([]);
  const modalRef = useRef<any>();
  const [loading, setLoading] = useState(false);

  const fetchTaxes = useCallback(() => {
    if (organisationId) {
      setLoading(true);
      ApiClient.getTaxes(organisationId)
        .then(resp => setTaxes(resp.data))
        .catch(notifyError)
        .finally(() => setLoading(false));
    }
  }, [organisationId]);

  useEffect(() => {
    fetchTaxes();
  }, [fetchTaxes]);

  return <>
    <Flex direction="row" align="center">
      <IconReceiptTax size={35} stroke={1.5}/>
      <Title size={24}>
        Taxes
      </Title>
      <Space w={20}/>
      <Button size="xs" rightSection={<IconPlus/>} onClick={() => modalRef.current?.openModal(undefined)}>
        New
      </Button>
    </Flex>
    <Box style={{paddingTop: '15px'}}>
      <LoadingOverlay visible={loading}/>
      <Table style={{width: "1%", minWidth: "700px"}} verticalSpacing={1} withTableBorder withColumnBorders>
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{width: "1%", textAlign: "right"}}>
              <IconColumns3 style={{marginTop: '5px'}}/>
            </Table.Th>
            <Table.Th>Name</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "right"}}>Rate</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "right"}}>Active</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Created</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "center"}}>Updated</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {taxes.map((tax, idx) => (
            <Table.Tr key={idx} className={tax.active ? '' : 'inactive'}>
              <Table.Td style={{textAlign: "center"}} onClick={() => modalRef.current?.openModal(tax)}>
                {idx + 1}
              </Table.Td>
              <Table.Td onClick={() => modalRef.current?.openModal(tax)}>
                {tax.name}
              </Table.Td>
              <Table.Td style={{textAlign: "right"}} onClick={() => modalRef.current?.openModal(tax)}>
                {tax.rate * 100}%
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => modalRef.current?.openModal(tax)}>
                {tax.active && <IconCheck stroke={1.5} color={'green'}/>}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}}
                        onClick={() => modalRef.current?.openModal(tax)}>
                {formatDateTime(tax.createTime)}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}}
                        onClick={() => modalRef.current?.openModal(tax)}>
                {formatDateTime(tax.updateTime)}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Box>

    <TaxModal ref={modalRef} onSuccess={fetchTaxes}/>
  </>;
}
