import Select, {GroupBase, OptionsOrGroups, SingleValue} from "react-select";
import React, {useRef} from "react";

export type Option = SingleValue<{ value: string, label: string }>;


interface Props {
  value: string
  options?: OptionsOrGroups<any, GroupBase<any>>
  onChange: (option: Option) => void
  defaultInputValue?: string
  modalRef?: React.MutableRefObject<any>
}

export const DataSheetSelect = ({
                                  value,
                                  options,
                                  onChange,
                                  defaultInputValue,
                                  modalRef
                                }: Props) => {
  const opts = [{value: 'CREATE', label: '+ Create new'}]
  const x = options?.map(o => ({value: o.value, label: o.label})) ?? [];
  opts.push(...x);

  const handleOnChange = (option: Option) => {
    if (option?.value === 'CREATE') {
      modalRef?.current?.openModal(undefined);
    } else {
      onChange(option);
    }
  }

  const defaultValue = (options ?? []).find(o => o.value === defaultInputValue)?.label ?? defaultInputValue;

  return <>
    <Select options={opts}
            value={(options ?? []).find(o => o.value === value)}
            defaultInputValue={defaultValue ?? ''}
            menuPortalTarget={document.body}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Tab') {
                e.stopPropagation();
              }
              if (e.key === 'Escape') {
                e.stopPropagation();
                onChange((options ?? []).find(o => o.value === value));
              }
            }}
            onChange={handleOnChange}
            openMenuOnFocus={true}
            closeMenuOnSelect={true}
            blurInputOnSelect={true}
            tabSelectsValue={true}
            menuShouldScrollIntoView={true}
            placeholder={""}
            autoFocus
            styles={{
              control: (prevStyle, state) => ({
                ...prevStyle,
                padding: 0,
                borderRadius: 0,
                borderColor: 'blueviolet',
                background: "white",
                height: '25px',
                minHeight: '25px',
                "&:hover": {
                  borderColor: 'blueviolet',
                  background: "white"
                }
              }),
              valueContainer: (prevStyle, state) => ({
                ...prevStyle,
                padding: 0,
              }),
              menu: (prevStyle, state) => ({
                ...prevStyle,
                minWidth: '200px'
              }),
              menuPortal: (prevStyle, state) => ({
                ...prevStyle,
                fontSize: 14,
                zIndex: 9999,
              }),
              input: (prevStyle, state) => ({
                ...prevStyle,
                background: "transparent",
                padding: 0,
                "&:hover": {
                  background: "transparent",
                }
              }),
              indicatorsContainer: (prevStyle, state) => ({
                ...prevStyle,
                display: 'none'
              })
            }}
    />
  </>
}