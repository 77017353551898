import {Button, Fieldset, Flex, LoadingOverlay, Modal, Switch} from "@mantine/core";
import React, {FormEvent, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {ApiAddress, ApiClient, ApiContact, UpsertContactRequest} from "../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import {IconCheck, IconCircleX} from "@tabler/icons-react";
import {notifyError, notifySavedChanges} from "../../../../utils/notificationUtils";
import {TextInput} from "../../../../common/TextInput";
import {initIfEmpty} from "../../../../utils/objectUtils";
import {ModalTitle} from "../../../../common/ModalTitle";

interface Props {
  onSuccess?: (apiContact: ApiContact) => void
  onClose?: () => void
}

const emptyContact = () => {
  return {
    supplier: true,
    active: true,
    address: {} as ApiAddress
  } as ApiContact;
}

export const ContactModal = React.forwardRef(({onSuccess, onClose}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const [contactId, setContactId] = useState<string | undefined>();
  const [contact, setContact] = useState<ApiContact>(emptyContact());

  const fetchEntities = () => {
    if (organisationId && contactId) {
      ApiClient.getContact(organisationId, contactId)
        .then(contact => setContact(contact.data))
        .catch(notifyError)
        .finally(() => setLoading(false));
    } else {
      setContact(emptyContact());
    }
  }

  useEffect(() => {
    fetchEntities();
  }, [organisationId, contactId]);

  const openModal = useCallback((contactId?: string) => {
    setContactId(contactId);
    if (contactId === undefined) {
      setContact(emptyContact());
    }
    open();
  }, [open, setContactId]);

  const closeModal = useCallback(() => {
    onClose?.();
    close();
    setContactId(undefined);
  }, [close]);

  useImperativeHandle(ref, () => ({openModal}));

  const handleSave = (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    setLoading(true);
    const request = contact as UpsertContactRequest;
    (contact.id
      ? ApiClient.updateContact(organisationId ?? '', contact.id, request)
      : ApiClient.createContact(organisationId ?? '', request))
      .then((resp) => {
        closeModal();
        notifySavedChanges();
        onSuccess?.(resp.data);
      })
      .catch(notifyError)
      .finally(() => setLoading(false));
  }

  return <>
    <LoadingOverlay visible={loading}/>
    <Modal opened={opened}
           onClose={closeModal}
           title={<ModalTitle name={"Contact"} id={contactId}/>}
           closeOnClickOutside={false}
           transitionProps={{duration: 100}}
           overlayProps={{opacity: 0.5}}
           size="auto"
    >
      <Flex align="center" direction="column" gap="md" style={{width: '100%'}}>
        <Flex direction="row">
          <Flex direction="column">

            <Fieldset legend="General information" disabled={loading}>
              <Flex direction="column" gap={5}>
                <TextInput placeholder="Some company LTD"
                           label="Name"
                           value={contact?.name}
                           onChange={(value) => setContact({...contact, name: value})}
                />

                <TextInput placeholder="e.g. OIB"
                           label="Registration Number"
                           value={contact?.registrationNumber}
                           onChange={(value) => setContact({...contact, registrationNumber: value})}
                />
              </Flex>

              <Flex direction="row" justify="space-around" style={{paddingTop: '10px'}} gap={5}>
                <Switch size={"sm"}
                        labelPosition="left"
                        label="Supplier"
                        checked={contact.supplier ?? true}
                        onChange={e => setContact({...contact, supplier: e.target.checked})}
                />
                <Switch size={"sm"}
                        labelPosition="left"
                        label="Customer"
                        checked={contact.customer ?? false}
                        onChange={e => setContact({...contact, customer: e.target.checked})}
                />
              </Flex>
            </Fieldset>

            <Fieldset legend="Contanct information" disabled={loading}>
              <Flex direction="column" gap={5}>
                <TextInput placeholder="email@company.com"
                           label="Email"
                           value={contact?.email}
                           onChange={(value) => setContact({...contact, email: value})}
                />
                <TextInput placeholder="+385 01 234 5678"
                           label="Phone number"
                           value={contact?.phoneNumber}
                           onChange={(value) => setContact({...contact, phoneNumber: value})}
                />
              </Flex>
            </Fieldset>

            <Fieldset legend="Financial information" disabled={loading}>
              <TextInput placeholder="HR3423600009567811941"
                         label="IBAN"
                         value={contact?.iban}
                         onChange={(value) => setContact({...contact, iban: value})}
              />
            </Fieldset>

          </Flex>

          <Fieldset legend="Address" disabled={loading}>
            <Flex direction="column" gap={5}>
              <TextInput placeholder="Address First Line"
                         label="Address Line 1"
                         value={contact?.address?.line1 ?? null}
                         onChange={(value) => setContact({
                           ...contact,
                           address: {
                             ...initIfEmpty(contact.address),
                             line1: value
                           }
                         })}
              />
              <TextInput placeholder="Address Second Line"
                         label="Address Line 2"
                         value={contact?.address?.line2 ?? null}
                         onChange={(value) => setContact({
                           ...contact,
                           address: {
                             ...initIfEmpty(contact.address),
                             line2: value
                           }
                         })}
              />
              <TextInput placeholder="10000"
                         label="Postal code"
                         value={contact?.address?.postalCode ?? null}
                         onChange={(value) => setContact({
                           ...contact,
                           address: {
                             ...initIfEmpty(contact.address),
                             postalCode: value
                           }
                         })}
              />
              <TextInput placeholder="Zagreb"
                         label="City"
                         value={contact?.address?.city ?? null}
                         onChange={(value) => setContact({
                           ...contact,
                           address: {
                             ...initIfEmpty(contact.address),
                             city: value
                           }
                         })}
              />
              <TextInput placeholder="Croatia"
                         label="Country"
                         value={contact?.address?.country ?? null}
                         onChange={(value) => setContact({
                           ...contact,
                           address: {
                             ...initIfEmpty(contact.address),
                             country: value
                           }
                         })}
              />
            </Flex>
          </Fieldset>

        </Flex>

        <Flex direction="row" justify="right" style={{width: "100%", paddingTop: '30px'}}>
          <Switch size={"sm"}
                  labelPosition="left"
                  label="Active"
                  style={{paddingTop: '5px'}}
                  checked={contact.active ?? true}
                  onChange={e => setContact({...contact, active: e.target.checked})}
          />
        </Flex>

        <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
          <Button type="submit" rightSection={<IconCheck/>} onClick={handleSave} loading={loading}>
            Save
          </Button>
          <Button variant="outline" rightSection={<IconCircleX/>} onClick={closeModal} disabled={loading}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  </>;
});