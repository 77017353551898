import {DateInputProps} from "@mantine/dates/lib";
import dayjs from "dayjs";

const parseNumber = (str: string, fallback: number) => {
  if (!str) {
    return fallback;
  }
  const number = Number(str);
  return isNaN(number) ? fallback : number;
}

export const dateParser: DateInputProps['dateParser'] = (input: string) => {
  const parts = input.split(".");
  const day = parseNumber(parts[0], 1);
  const monthIndex = parseNumber(parts[1], 1) - 1;
  const year = parseNumber(parts[2], new Date().getFullYear());
  return new Date(year < 100 ? year + 2000 : year, monthIndex, day);
};

export const formatApiDate = (date: Date | null) => {
  return date !== null
    ? dayjs(date).format('YYYY-MM-DD')
    : null;
}

export const formatDate = (date: string | null) => {
  return date ? dayjs(date).format('D.M.YYYY') : '';
}

export const formatDateTime = (date: Date | null) => {
  return date ? dayjs(date).format('D.M.YYYY HH:mm') : '';
}

export const toUTCDate = (date: Date | null | undefined): Date | null => {
  return date
    ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    : null;

}

export const mapDate = (date: string | null | undefined) => {
  return date
    ? toUTCDate(dayjs(date).toDate())
    : null;
}

export const today = () => {
  return new Date();
}

const DAY_MAP = [6, 0, 1, 2, 3, 4, 5]; // Monday is the first day fo the month for us

export const startOfWeek = () => {
  const date = new Date();
  const weekStart = date.getDate() - DAY_MAP[date.getDay()];
  return new Date(date.setDate(weekStart));
}

export const startOfMonth = () => {
  return new Date(today().setDate(1));
}

export const startOfYear = () => {
  return new Date(startOfMonth().setMonth(0));
}

export const daysAgo = (days: number) => {
  return dayjs().subtract(days, 'days').toDate();
}
