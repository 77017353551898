import React, {useEffect} from 'react';
import {useAuth} from "./hooks/useAuth";
import {PrivateShell} from "./shell/private/PrivateShell";
import {useLocation} from "react-router-dom";
import {PublicShell} from "./shell/public/PublicShell";

export const App = () => {
  const {setupRefreshTask} = useAuth();
  const location = useLocation();

  useEffect(() => {
    setupRefreshTask();
  }, [setupRefreshTask]);

  return location.pathname.startsWith("/app")
    ? <PrivateShell/>
    : <PublicShell/>;
}