import {BookingType, ExtractionStatus, FileStatus, InvoiceStatus} from "../utils/http/apiClient";
import {Badge} from "@mantine/core";
import React from "react";

interface Props {
  status?: ExtractionStatus | FileStatus | InvoiceStatus | BookingType
}

export const StatusBadge = ({status}: Props) => {
  let color = 'gray';
  switch (status ?? 'CREATIN') {
    case 'CREATING':
      color = 'gray';
      break;
    case 'IN_PROGRESS':
    case 'DRAFT':
      color = 'yellow';
      break;
    case 'COMPLETE':
    case 'EXTRACTED':
    case 'BOOKED':
    case "FULL":
      color = 'blue';
      break;
    case 'PARTIAL':
    case 'IN_REVIEW':
      color = 'grape';
      break;
    case 'UNREADABLE':
      color = 'red';
      break;
  }
  // style={{display: 'block', width: 'fit-content'}}>
  return <Badge color={color} opacity={0.7} style={{display: 'flex'}}>
    {status ?? 'CREATING'}
  </Badge>
}