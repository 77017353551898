import {Button, Space, Stack, Table} from "@mantine/core";
import {IconCheck, IconColumns3, IconPlus} from "@tabler/icons-react";
import React, {useRef} from "react";
import {ApiItem, ApiItemCategory, ApiUnit} from "../../../../utils/http/apiClient";
import {createIdMap} from "../../../../utils/objectUtils";
import {ItemModal} from "./ItemModal";

interface Props {
  items: ApiItem[]
  categories: ApiItemCategory[],
  units: ApiUnit[],
  fetchEntities: () => void
}

export const ItemsTab = ({items, categories, units, fetchEntities}: Props) => {
  const unitsById = createIdMap(units);
  const categoriesById = createIdMap(categories);
  const itemModalRef = useRef<any>();

  return <>
    <Space h={10}/>
    <Stack dir="column" align="flex-start">
      <Button size="xs" rightSection={<IconPlus/>} onClick={() => itemModalRef.current?.openModal(undefined)}>
        New
      </Button>

      <Table verticalSpacing={1}
             withTableBorder
             withColumnBorders
             highlightOnHover={true}
             className={'datatable'}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{width: "1%", textAlign: "right"}}>
              <IconColumns3 style={{marginTop: '5px'}}/>
            </Table.Th>
            <Table.Th>Name</Table.Th>
            <Table.Th>Category</Table.Th>
            <Table.Th>Unit</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "right"}}>Sold</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "right"}}>Stocked</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "right"}}>Produced</Table.Th>
            <Table.Th style={{width: "1%", textAlign: "right"}}>Active</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {items.map((item, idx) => (
            <Table.Tr key={idx} className={item.active ? '' : 'inactive'}>
              <Table.Td style={{textAlign: "center"}} onClick={() => itemModalRef.current?.openModal(item)}>
                {idx + 1}
              </Table.Td>
              <Table.Td onClick={() => itemModalRef.current?.openModal(item)}>
                {item.name}
              </Table.Td>
              <Table.Td onClick={() => itemModalRef.current?.openModal(item)}>
                {categoriesById[item.categoryId]?.name ?? ''}
              </Table.Td>
              <Table.Td onClick={() => itemModalRef.current?.openModal(item)}>
                {unitsById[item.unitId ?? '']?.shortName}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => itemModalRef.current?.openModal(item)}>
                {item.sold && <IconCheck size={16} stroke={1.5}/>}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => itemModalRef.current?.openModal(item)}>
                {item.stocked && <IconCheck size={16} stroke={1.5}/>}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => itemModalRef.current?.openModal(item)}>
                {item.produced && <IconCheck size={16} stroke={1.5}/>}
              </Table.Td>
              <Table.Td style={{textAlign: "center"}} onClick={() => itemModalRef.current?.openModal(item)}>
                {item.active && <IconCheck size={16} stroke={1.5}/>}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Stack>
    <ItemModal ref={itemModalRef} onSuccess={fetchEntities}/>
  </>
}