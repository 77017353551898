import {Button, Checkbox, Flex, Grid, Modal, Text} from "@mantine/core";
import React, {FormEvent, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {ApiClient, ApiRegister} from "../../../../utils/http/apiClient";
import {useOrganisationId} from "../../../../hooks/useOrganisationId";
import {IconCheck, IconCircleX} from "@tabler/icons-react";
import {notifyError, notifySavedChanges} from "../../../../utils/notificationUtils";
import {ModalTitle} from "../../../../common/ModalTitle";
import {TextInput} from "../../../../common/TextInput";

interface Props {
  onSuccess?: (apiRegister: ApiRegister) => void
  onClose?: () => void
}

const emptyRegister = () => {
  return {
    active: true
  } as ApiRegister;
}


export const RegisterModal = React.forwardRef(({onSuccess, onClose}: Props, ref) => {
  const organisationId = useOrganisationId();
  const [loading, setLoading] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);
  const [registerId, setRegisterId] = useState<string | undefined>(undefined);
  const [register, setRegister] = useState(emptyRegister());

  const fetchEntities = () => {
    if (organisationId && registerId) {
      ApiClient.getRegister(organisationId, registerId)
        .then(register => setRegister(register.data))
        .catch(notifyError)
        .finally(() => setLoading(false));
    } else {
      setRegister(emptyRegister());
    }
  }

  useEffect(() => {
    fetchEntities();
  }, [organisationId, registerId]);

  const openModal = useCallback((registerId?: string) => {
    setRegisterId(registerId);
    if (registerId === undefined) {
      setRegister(emptyRegister());
    }
    open();
  }, [open, setRegisterId]);

  const closeModal = useCallback(() => {
    onClose?.();
    close();
    setRegisterId(undefined);
  }, [close]);

  useImperativeHandle(ref, () => ({openModal}));

  const handleSave = (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    setLoading(true);
    (registerId
      ? ApiClient.updateRegister(organisationId ?? '', registerId, register)
      : ApiClient.createRegister(organisationId ?? '', register))
      .then((resp) => {
        closeModal();
        onSuccess?.(resp.data);
        notifySavedChanges();
      })
      .catch(notifyError)
      .finally(() => setLoading(false));
  }

  return <>
    <Modal opened={opened}
           id="tax_modal"
           onClose={closeModal}
           title={<ModalTitle name={"Register"} id={registerId}/>}
           closeOnClickOutside={false}
           returnFocus={true}
           transitionProps={{duration: 100}}
           overlayProps={{opacity: 0.5}}
           size="auto">
      <Grid>
        <Grid.Col span={3}>
          <Text size="sm">Name</Text>
        </Grid.Col>
        <Grid.Col span={9}>
          <TextInput placeholder="Some register name"
                     label="Name"
                     value={register.name}
                     disabled={loading}
                     onChange={value => setRegister({...register, name: value})}
          />
        </Grid.Col>

        <Grid.Col span={3}>
          <Text size="sm">Active</Text>
        </Grid.Col>
        <Grid.Col span={9}>
          <Checkbox checked={register.active ?? undefined}
                    onChange={e => setRegister({...register, active: e.target.checked})}/>
        </Grid.Col>
      </Grid>

      <Flex direction="row" justify="space-around" style={{width: "100%", paddingTop: '30px'}}>
        <Button type="submit" rightSection={<IconCheck/>} loading={loading} onClick={handleSave}>
          Save
        </Button>
        <Button variant="outline" rightSection={<IconCircleX/>} onClick={closeModal} disabled={loading}>
          Cancel
        </Button>
      </Flex>
    </Modal>
  </>;
});