import {AppShell} from "@mantine/core";
import {Outlet} from "react-router-dom";
import React from "react";
import {PublicShellHeader} from "./PublicShellHeader";

export const PublicShell = () => {
  return <AppShell header={{height: 60}} padding="md">
    <AppShell.Header>
      <PublicShellHeader/>
    </AppShell.Header>

    <AppShell.Main>
      <Outlet/>
    </AppShell.Main>
  </AppShell>;
}